import React, { useContext, useEffect } from 'react'
import { Link , Outlet } from 'react-router-dom'
import "./EmpCard.css"
import InContext from './InContext'
import { useNavigate, useLocation } from 'react-router-dom'

function EmpCard() {
  const history = useNavigate();
  const context = useContext(InContext);
  const { setCls, setPndng, pndng, cls, setProducts } = context;
  const PendingLength = pndng.length;
  const CloseLength = cls.length;
  const hostname = process.env.REACT_APP_SERVER

  //            FETCHING INVENTORY PRODUCTS
  const ProductDetails = async () => {
    const url = `${hostname}/api/Inventory/fetchProduct`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const prdctDtls = await response.json()
    setProducts(prdctDtls)
  }

  //  Fetching Employee's Closed Tickets

  const empClose = async () => {
    const url = `${hostname}/api/Employee/fetchClose`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const EClose = await response.json()
    setCls(EClose.reverse())
  }

  useEffect(() => {
    if (localStorage.getItem('token')) {
      empClose()
    }
    else {
      history('/')
    }
  }, [])

  //  Fetching Employee's Pending Tickets

  const empPending = async () => {
    const url = `${hostname}/api/Employee/fetchPending`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const EPending = await response.json()
    setPndng(EPending)
  }

  useEffect(() => {
    if (localStorage.getItem('token')) {
      empPending()
    }
    else {
      history('/')
    }
  }, [])
  useEffect(() => {
    if (localStorage.getItem('token')) {
      ProductDetails()
    }
    else {
      history('/')
    }
  }, [])

  let location = useLocation();
  useEffect(() => {
  }, [location])


  return (
    <>
      <div className='container'>
        <h5 style={{ borderBottom: "solid" }} className="my-2"><i className="uil uil-bars"> EMPLOYEE - Tickets</i></h5>
        <div className='row justify-content-center my-2'>
          <div className='Empcrd1 col-2'>
            <Link to="/Employee">
              <div className={`card  mb-3 ${location.pathname === "/Employee" ? "active" : ""}`} style={{ maxWidth: "10rem", maxHeight: "10rem" }}>
                <div className="card text-center" >Pending Tickets</div>
                <div className="card-body">
                  <h5 className="card-title text-center ">{PendingLength}</h5>
                </div></div></Link>
          </div>

          <div className='Empcrd2 col-2'>
            <Link to="/Employee/Closed">
              <div className={`card  mb-3 ${location.pathname === "/Employee/Closed" ? "active" : ""}`} style={{ maxWidth: "10rem", maxHeight: "10rem" }}>
                <div className="card text-center">Closed Tickets</div>
                <div className="card-body">
                  <h5 className="card-title text-center">{CloseLength}</h5>
                </div>
              </div> </Link>
          </div>
        </div>
        <Outlet/>
      </div>
    </>
  )
}

export default EmpCard