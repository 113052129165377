import React,{useEffect} from 'react'
import { Link, useNavigate,useLocation  } from 'react-router-dom'

function Navbar() {
    const history = useNavigate();
    const Logout = () => {
        localStorage.removeItem('token')
        history('/')
      }
      let location = useLocation();
    useEffect(() => {
    }, [location])
 
    return (
    <nav className="navbar navbar-expand-md navbar  ">
    <span className="navbar-brand bs-bold mx-3" >BHAIYA JI</span>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
      <i class="uil uil-align-justify"></i>
    </button>
    <div className="collapse navbar-collapse" id="collapsibleNavbar">
      <ul className="navbar-nav">
      <li className="nav-item mx-3">
      { location.pathname==="/Employee/Closed/Print"? <li className="nav-item mx-3">
        <Link className="nav-link active align-middle" to="Profile">Invoice</Link>
        </li>:<li className='nav-item mx-3'><Link className={`nav-link ${location.pathname==="/Employee" ||location.pathname==="/Employee/Closed"?"active":""} align-middle`} to="/Employee">Home</Link></li>}
        </li>
        <li className="nav-item mx-3">
        <Link className={`nav-link ${location.pathname==="/Employee/Profile"?"active":""} align-middle`}to="Profile">My Profile</Link>
        </li>

        <li className="nav-item mx-2 my-1">
         <button className="btn btn-dark" id="lgout"  onClick={Logout} type="button">Logout</button> 
        </li>
      </ul>
    </div>
  </nav>
  )
}

export default Navbar