import React, { useContext } from 'react'
import "./EmpCard.css"
import Tckt from './Tckt';
import InContext from './InContext'

function EmpTicket() {
  const context = useContext(InContext);
  const { pndng } = context;

 return (
    <>
      <div className='container-ticket'>
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col " className='text-center sno'>S.No</th>
              <th scope="col " className='text-center tcktno'>Ticket No</th>
              <th scope="col " className='text-center name' >Name</th>
              <th scope="col " className='text-center complain'>Complaint Description</th>
              <th scope="co " className='text-center mail ' >Email</th>
              <th scope="col " className='text-center number'>Contact</th>
              <th scope="col" className='text-center  address' >Address</th>
              <th scope="col" className='text-center  landmark'>Landmark</th>
              <th scope="col " className='text-center city' >City</th>
              <th scope="col " className='text-center state'>State</th>
              <th scope="col" className='text-center  zip' >Zip</th>
              {/* <th scope="col " className='text-center country'>Country</th> */}
              <th scope="col" className='text-center status' >Status</th>
              <th scope="col" className='text-center'>Generate Invoice</th>
            </tr>
          </thead>
          <tbody>
            {
              pndng.map((element, index) => {
                return <Tckt key = {index} index= {index + 1} Name = {element.Name} ComplainType = {element.ComplainType}
                 Email = {element.Email} PhoneNo = {element.PhoneNo} Address= {element.Address} Landmark= {element.Landmark}
                  City = {element.City}  State = {element.State} Zip= {element.Zip}  Country = {element.Country} Status= {element.Status}
                  id = {element._id} empAssign = {element.empAssign} TicketNo = {element.TicketNo} />
                  }
                  )}
          </tbody>
        </table>
      </div>
    </>
  )
} 
               
                   
              
            

export default EmpTicket