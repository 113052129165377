import React, {useContext } from 'react'
import InContext from './InContext'
import { useNavigate } from 'react-router-dom'
import { actionCreater } from '../../State/index'
import { useDispatch } from "react-redux";



function ClosedTickets() {
  const dispatch = useDispatch();
  const context = useContext(InContext);
  const { cls } = context;
  const history = useNavigate();
  const hostname = process.env.REACT_APP_SERVER

  const Prnt = async (TicketNo) => {
     const url = `${hostname}/api/Employee/prnt?TicketNo=${TicketNo}`
     const response = await fetch(url)
     const Prnt = await response.json()
     dispatch(actionCreater.PrintInvoice(Prnt))
     history("/Employee/Closed/Print")
  }


  return (
    <div className='container-ticket'>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            {/* <th scope="col" className='text-center sno'>S.No</th> */}
            <th scope="col" className='text-center tcktno'>Ticket no</th>
            <th scope="col" className='text-center name' >Name</th>
            <th scope="col" className='text-center complain'>Complaint Description</th>
            <th scope="col" className='text-center mail' >Email</th>
            <th scope="col" className='text-center number'>Contact</th>
            <th scope="col" className='text-center address' >Address</th>
            <th scope="col" className='text-center landmark'>Landmark</th>
            <th scope="col" className='text-center city' >City</th>
            <th scope="col" className='text-center state'>State</th>
            <th scope="col" className='text-center zip' >Zip</th>
            {/* <th scope="col" className='text-center country'>Country</th> */}
            <th scope="col" className='text-center status' >Status</th>
            <th scope="col" className='text-center invoice'>Invoice</th>
          </tr>
        </thead>
        <tbody>
          {
            cls.map((element, index) => {
              return <tr key={index}>
                {/* <th scope="row" className='text-center sno'>{index + 1}</th> */}
                <th scope="row" className='text-center tcktno'>{element.TicketNo}</th>
                <td className='text-center name' >{element.Name}</td>
                <td className='text-center complain' >{element.ComplainType}</td>
                <td className='text-center mail' >{element.Email}</td>
                <td className='text-center number' >{element.PhoneNo}</td>
                <td className='text-center address' >{element.Address}</td>
                <td className='text-center landmark' >{element.Landmark}</td>
                <td className='text-center city' >{element.City}</td>
                <td className='text-center state' >{element.State}</td>
                <td className='text-center zip' >{element.Zip}</td>
                {/* <td className='text-center country' >{element.Country}</td> */}

                <td className='text-center status' >
                  <button className="btn btn-sm" style={{ borderRadius: "16px" , background : "var(--cardCT-clr)" ,color:"white" }} type="button">{element.Status}</button>
                </td>
                <td className='text-center'>
                <button className="btn btn-primary btn-sm" onClick={() => Prnt(element.TicketNo)} style={{ borderRadius: "16px" }} type="button">Invoice</button>
                </td>
              </tr>
            })
          }
        </tbody>
      </table>
    </div>
  )
}

export default ClosedTickets