import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { actionCreater } from '../../State/index'

function Distributer() {
  
  const dispatch = useDispatch();
  const history = useNavigate();
  const [details, setDetails] = useState([])
  let Refer = ""
  const [myReferInv, setMyReferInv] = useState([])
  let TotalDisAmount = 0;
  let pendingAmount = 0;
  let paidAmount=0;
  const hostname = process.env.REACT_APP_SERVER

  //                                                      SEE INVOICE
  const Prnt = async (TicketNo) => {
    const url = `${hostname}/api/Employee/prnt?TicketNo=${TicketNo}`
    const response = await fetch(url)
    const Prnt = await response.json()
    dispatch(actionCreater.PrintInvoice(Prnt))
    history("/Distributer/TicketInvoice")
 }


  //                    FETCHING REFERALL INVOICE
  const fetchRefer = async (Refer) => {
    const url = `${hostname}/api/Distributer/fetchRefer?Refer=${Refer}`
    const response = await fetch(url)
    const Dist = await response.json()
    setMyReferInv(Dist)
  }

  myReferInv.map((element) => {
    let disamt = element.TotalPrice * 0.1
    return TotalDisAmount = TotalDisAmount + disamt;
  })

  //                           FETCHING DISTRIBUTER DETAILS
  const DistDetails = async () => {
    const url = `${hostname}/api/Distributer/fetchDetails`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const data = await response.json()
    Refer = data.map(e => e.Refer)
    fetchRefer(Refer)
    setDetails(data);
  }

  useEffect(() => {
    if (localStorage.getItem('token')) {
      DistDetails()
    }
    else {
      history('/')
    }
  }, [])

  details.map((e)=>{
    return e.Payments.map((element)=>{
       paidAmount = paidAmount + parseInt(element.Amount);
    return pendingAmount = TotalDisAmount-paidAmount
    })
  })
  



  return (
    <>
      <h5 style={{ borderBottom: "solid" }} className="header"><i class="uil uil-bars">DISTRIBUTER - Referral </i> </h5>
      <div className="d-flex justify-content-end">
      <button className="btn btn-danger btn-sm"  type="button" style={{borderRadius : "16px"}}>Pending Amount = {pendingAmount}</button>
        <button className="btn btn-dark btn-sm mx-2" id="Ad" type="button">Total Amount = {TotalDisAmount}</button>
        
      </div>

      <div className='container-ticket' style={{height : "83vh"}}>
        <table className="table table-bordered table-striped my-3">
          <thead>
            <tr>
              <th scope="col" className='text-center'>Ticket Number</th>
              <th scope="col" className='text-center'>Full Name</th>
              <th scope="col" className='text-center'>Address</th>
              <th scope="col" className='text-center'>City</th>
              <th scope="col" className='text-center'>State</th>
              <th scope="col" className='text-center'>Invoice Date</th>
              <th scope="col" className='text-center'>Total Price</th>
              <th scope="col" className='text-center'>Discounted Amount</th>
              <th scope="col" className='text-center'>Invoice</th>

            </tr>
          </thead>
          <tbody>
            {
              myReferInv.map((element, index) => {
                return <tr key={index}>
                  <th scope="row" className='text-center'>{element.TicketNo}</th>
                  <td className='text-center'>{element.Name}</td>
                  <td className='text-center'>{element.Address}</td>
                  <td className='text-center'>{element.City}</td>
                  <td className='text-center'>{element.State}</td>
                  <td className='text-center'>{element.InDate}</td>
                  <td className='text-center'>{element.TotalPrice}</td>
                  <td className='text-center'>{0.1 * element.TotalPrice}</td>
                  <td className='text-center'>
                <button className="btn btn-primary btn-sm" onClick={() => Prnt(element.TicketNo)} style={{ borderRadius: "16px" }} type="button">Invoice</button>
                </td>
                </tr>
              })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Distributer