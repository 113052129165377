import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { actionCreater } from '../../../State/index'
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';


function UpdateDis() {
    const hostname = process.env.REACT_APP_SERVER
    const dispatch = useDispatch()
    const [details, setDetails] = useState({})
    const [ReferInv, setReferInv] = useState([])
    const Edit = useSelector((state) => state.Edit)
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    const refClose = useRef(null);
    let TotalAmount = 0;
    let pendingAmount = 0;
    let paidAmount = 0;
    // console.log(Edit);
    const id = Edit.id;

    //                                                         UPDATE DISTRIBUTER  
    const update = async () => {
        const url = `${hostname}/api/Admin/Distributer/updateDis/${id}`
        const EDIT = await fetch(url,
            {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    Name: details.Name, Email: details.Email, PhoneNo: details.PhoneNo, Address: details.Address, City: details.City, State: details.State,
                    Country: details.Country, Zip: details.Zip, Account: details.Account, IFSC: details.IFSC
                })
            });
        const Edit = await EDIT.json();
        alert("Distributer Updated Successfully");
        dispatch(actionCreater.Edit(Edit))
        dispatch(actionCreater.UpdateDis(Edit))

    }
    //                                                     DELETE DISTRIBUTER

    const DeleteDis = async () => {
        const url = `${hostname}/api/Admin/Distributer/deleteDistributer/${id}`
        const Del = await fetch(url,
            {
                method: 'Delete',
                headers: { 'Content-Type': 'application/json' }
            });
        const Delete = await Del.json();
        // console.log(Edit)
        alert("Employee Deleted Successfully");
        dispatch(actionCreater.DeleteDis(Delete))
    }

    //                                                         FETCH DISTRIBUTER INVOICE 

    const fetchRefer = async () => {
        const url = `${hostname}/api/Distributer/fetchRefer?Refer=${Edit.Refer}`
        const response = await fetch(url)
        const Dist = await response.json()
        // console.log(Dist);
        setReferInv(Dist)
    }
    useEffect(() => { fetchRefer() }, [])

    ReferInv.map((element) => {
        let disamt = element.TotalPrice * 0.1
        return TotalAmount = TotalAmount + disamt;
    })

    if (Edit.Payments.length >= 1) {
        Edit.Payments.map((element) => {
            paidAmount = parseInt(element.Amount) + paidAmount;
            // console.log(paidAmount);
            pendingAmount = TotalAmount - paidAmount
        })
    }
    else {
        pendingAmount = TotalAmount
    }


    // PAY TO DISTRIBUTER

    const payDistributer = async () => {
        if (details.Amount < pendingAmount) {
            const url = `${hostname}/api/Admin/Distributer/payment/${id}`
            const response = await fetch(url, {
                method: "PUT",
                body: JSON.stringify({ "Date": date, "Amount": details.Amount, "ReferenceID": details.ReferenceID }),
                headers: { "Content-Type": "application/json" }
            })

            const pay = await response.json();
            dispatch(actionCreater.UpdateDis(pay));
            dispatch(actionCreater.Edit(pay));
            await sendSMSToDealer()
            refClose.current.click()
        }
        else {
            refClose.current.click()
            alert("Payment to dealer should not be greater than pending amount.")
        }
    }


    const onChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value })
    }

    const sendSMSToDealer = async () => {
        const number = Edit.PhoneNo;
        const amount = details.Amount;
        const disNewBalance = pendingAmount - details.Amount;
        const url = `${hostname}/api/Admin/Distributer/debitSmsToDealer`
        const response = await fetch(url, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "number": number, "amount": amount, "disNewBalance": disNewBalance })
        });
        const data = await response.json();
        // console.log(data);
    }


    return (
        <>
            {/* MODAL FOR PAYING TO DISTRIBUTER */}

            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Pay To Distributer</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-outline mb-4">
                                <label className="form-label"><strong>Amount</strong></label>
                                <input type="text" onChange={onChange} name="Amount" required="true" className="form-control" />
                                <label className="form-label"><strong>ReferenceID</strong></label>
                                <input type="text" onChange={onChange} name="ReferenceID" required="true" className="form-control" />

                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" ref={refClose} className="btn form-btn" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn form-btn" onClick={payDistributer} >PAY</button>
                        </div>
                    </div>
                </div>
            </div>


            <h5 style={{ borderBottom: "solid" }} className="my-2"><i className="uil uil-bars">DASHBOARD - Distributer</i></h5>
            <div className="d-flex justify-content-end">
                <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" style={{ borderRadius: "12px" }} data-bs-target="#exampleModal">Pay</button>
                <button className="btn btn-danger btn-sm mx-2" style={{ borderRadius: "12px" }} type="button">Pending Amount = Rs.{pendingAmount}</button>
                <button className="btn btn-success btn-sm mx-2" style={{ borderRadius: "12px" }} type="button">Total Amount = Rs.{TotalAmount} </button>
                <Link to="Payments"> <button className="btn btn-dark btn-sm mx-2" id="Ad" type="button">Paid Transactions</button></Link>
                <Link to="RefPayments"> <button className="btn btn-dark btn-sm mx-2" id="Ad" type="button">Referral Transactions</button></Link>
            </div>

            <form className="row my-2">
                <div className="d-flex justify-content-center ">
                    <button className="btn btn-dark btn-sm" id="DisBtn" style={{ borderRadius: "16px" }} disabled> Update Employee</button>
                </div>
                <div className="d-flex justify-content-start ">
                    <button className="btn btn-primary btn-sm " style={{ borderRadius: "16px" }} id="DisBtn" disabled>Personal Details</button>
                </div>

                <div className="col-md-6">
                    <label className="form-label"><strong>Name*</strong></label>
                    <input type="text" className="form-control" defaultValue={Edit.Name} onChange={onChange} name="Name" />
                </div>
                <div className="col-md-6">
                    <label className="form-label"><strong>PhoneNo*</strong></label>
                    <input type="text" className="form-control" defaultValue={Edit.PhoneNo} onChange={onChange} name="PhoneNo" />
                </div>
                <div className="col-md-6 my-3">
                    <label className="form-label"><strong>Email*</strong></label>
                    <input type="email" className="form-control" defaultValue={Edit.Email} onChange={onChange} name="Email" />
                </div>

                <div className="col-md-6 my-3">
                    <label className="form-label"><strong>Address*</strong></label>
                    <input type="text" className="form-control" defaultValue={Edit.Address} onChange={onChange} name="Address" />
                </div>
                <div className="col-md-6">
                    <label className="form-label"><strong>City*</strong></label>
                    {

                        details.State === "Andhra Pradesh" ?
                            <select name="City" id="state" onChange={onChange} className="form-control">
                                <option >{Edit.City}</option>
                                <option value="Visakhapatnam">Visakhapatnam</option>
                                <option value="Vijayawada">Vijayawada</option>
                                <option value="Guntur">Guntur</option>
                            </select> :

                            details.State === "Arunanchal Pradesh" ?
                                <select name="City" id="state" onChange={onChange} className="form-control">
                                    <option >Select City</option>
                                    {/* <option value="Visakhapatnam">Visakhapatnam</option> 
                                         <option value="Vijayawada">Vijayawada</option> 
                                         <option value="Guntur">Guntur</option>  */}
                                </select> :

                                details.State === "Assam" ?
                                    <select name="City" id="state" onChange={onChange} className="form-control">
                                        <option >Select City</option>
                                        <option value="Guwahati">Guwahati</option>
                                        <option value="	Dibrugarh">	Dibrugarh</option>
                                        <option value="Silchar">Silchar</option>
                                    </select> :

                                    details.State === "Bihar" ?
                                        <select name="City" id="state" onChange={onChange} className="form-control">
                                            <option >Select City</option>
                                            <option value="Patna">	Patna</option>
                                            <option value="Gaya">Gaya</option>
                                            <option value="Bhagalpur">Bhagalpur</option>
                                            <option value="Muzaffarpur">Muzaffarpur</option>
                                        </select> :

                                        details.State === "Chhattisgarh" ?
                                            <select name="City" id="state" onChange={onChange} className="form-control">
                                                <option >Select City</option>
                                                <option value="Raipur">Raipur</option>
                                                <option value="Bhilai">Bhilai</option>
                                                <option value="Jagdalpur">Jagdalpur</option>
                                                <option value="Bilaspur">Bilaspur</option>
                                            </select> :
                                            details.State === "Goa" ?
                                                <select name="City" id="state" onChange={onChange} className="form-control">
                                                    <option >Select City</option>
                                                    <option value="Raipur">Raipur</option>
                                                    <option value="Bhilai">Bhilai</option>
                                                    <option value="Jagdalpur">Jagdalpur</option>
                                                    <option value="Bilaspur">Bilaspur</option>
                                                </select> :
                                                details.State === "Gujarat" ?
                                                    <select name="City" id="state" onChange={onChange} className="form-control">
                                                        <option >Select City</option>
                                                        <option value="Ahmedabad">	Ahmedabad</option>
                                                        <option value="Surat">Surat</option>
                                                        <option value="Vadodara">Vadodara</option>
                                                        <option value="Bhavnagar">Bhavnagar</option>
                                                    </select> :
                                                    details.State === "Haryana" ?
                                                        <select name="City" id="state" onChange={onChange} className="form-control">
                                                            <option >Select City</option>
                                                            <option value="Kurukshetra">Kurukshetra</option>
                                                            <option value="Gurgaon">Gurgaon</option>
                                                            <option value="Faridabad">Faridabad</option>
                                                            <option value="Panipat">Panipat</option>
                                                            <option value="Panchkula">Panchkula</option>
                                                        </select> :
                                                        details.State === "Himachal Pradesh" ?
                                                            <select name="City" id="state" onChange={onChange} className="form-control">
                                                                <option >Select City</option>
                                                                <option value="Shimla">Shimla</option>
                                                                <option value="Dharamsala">Dharamsala</option>
                                                                <option value="Solan">Solan</option>
                                                                <option value="Mandi">	Mandi</option>
                                                            </select> :
                                                            details.State === "Jammu and Kashmir" ?
                                                                <select name="City" id="state" onChange={onChange} className="form-control">
                                                                    <option >Select City</option>
                                                                    <option value="Shimla">Shimla</option>
                                                                    <option value="Dharamsala">Dharamsala</option>
                                                                    <option value="Solan">Solan</option>
                                                                    <option value="Mandi">	Mandi</option>
                                                                </select> :
                                                                details.State === "Jharkhand" ?
                                                                    <select name="City" id="state" onChange={onChange} className="form-control">
                                                                        <option >Select City</option>
                                                                        <option value="Jamshedpur">Jamshedpur</option>
                                                                        <option value="Dhanbad">Dhanbad</option>
                                                                        <option value="Ranchi">Ranchi</option>
                                                                        <option value="Bokaro Steel City">Bokaro Steel City</option>
                                                                    </select> :
                                                                    details.State === "Karnataka" ?
                                                                        <select name="City" id="state" onChange={onChange} className="form-control">
                                                                            <option >Select City</option>
                                                                            <option value="Bangalore">Bangalore</option>
                                                                            <option value="Hubli-Dharwad">Hubli-Dharwad</option>
                                                                            <option value="Mysore">Mysore</option>
                                                                            <option value="Gulbarga">Gulbarga</option>
                                                                        </select> :
                                                                        details.State === "Kerala" ?
                                                                            <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                <option >Select City</option>
                                                                                <option value="Thiruvananthapuram">Thiruvananthapuram</option>
                                                                                <option value="Kozhikode">Kozhikode</option>
                                                                                <option value="	Kochi">	Kochi</option>
                                                                                <option value="Kollam">Kollam</option>
                                                                            </select> :
                                                                            details.State === "Madhya Pradesh" ?
                                                                                <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                    <option >Select City</option>
                                                                                    <option value="Gwalior">Gwalior</option>
                                                                                    <option value="Bhopal">Bhopal</option>
                                                                                    <option value="Jabalpur">Jabalpur</option>
                                                                                    <option value="Indore">Indore</option>
                                                                                </select> :
                                                                                details.State === "Maharashtra" ?
                                                                                    <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                        <option >Select City</option>
                                                                                        <option value="Mumbai">Mumbai</option>
                                                                                        <option value="Pune">Pune</option>
                                                                                        <option value="Nagpur">Nagpur</option>
                                                                                        <option value="Nashik">Nashik</option>
                                                                                        <option value="Thane">Thane</option>
                                                                                    </select> :

                                                                                    details.State === "Manipur" ?
                                                                                        <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                            <option >Select City</option>
                                                                                            <option value="Imphal">Imphal</option>
                                                                                            <option value="Ukhru">Ukhru</option>
                                                                                            <option value="Bishnupur">Bishnupur</option>
                                                                                            <option value="Thoubal">Thoubal</option>
                                                                                        </select> :

                                                                                        details.State === "Meghalaya" ?
                                                                                            <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                <option >Select City</option>
                                                                                                <option value="Shillong">Shillong</option>
                                                                                                <option value="Cherrapunji">Cherrapunji</option>
                                                                                                <option value="Baghmara">Baghmara</option>
                                                                                                <option value="Dawki">Dawki</option>
                                                                                            </select> :

                                                                                            details.State === "Mizoram" ?
                                                                                                <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                    <option >Select City</option>
                                                                                                    <option value="Aizawl ">Aizawl </option>
                                                                                                    <option value="Lunglei">Lunglei</option>
                                                                                                    <option value="Mamit">Mamit</option>
                                                                                                    <option value="Kolasib">Kolasib</option>
                                                                                                </select> :
                                                                                                details.State === "Nagaland" ?
                                                                                                    <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                        <option >Select City</option>
                                                                                                        <option value="Changtongya">Changtongya</option>
                                                                                                        <option value="Chumukedima">Chumukedima</option>
                                                                                                        <option value="Dimapur">Dimapur</option>
                                                                                                        <option value="Diphupar-">Diphupar-</option>
                                                                                                    </select> :
                                                                                                    details.State === "Odhisa" ?
                                                                                                        <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                            <option >Select City</option>
                                                                                                            <option value="Bhubaneswar">Bhubaneswar</option>
                                                                                                            <option value="Cuttack">Cuttack</option>
                                                                                                            <option value="Raurkela">Raurkela</option>
                                                                                                            <option value="Brahmapur">Brahmapur</option>
                                                                                                            <option value="Puri">Puri</option>
                                                                                                        </select> :
                                                                                                        details.State === "Punjab" ?
                                                                                                            <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                <option >Select City</option>
                                                                                                                <option value="Ludhiana">Ludhiana</option>
                                                                                                                <option value="Amritsar">Amritsar</option>
                                                                                                                <option value="Chandigarh">Chandigarh</option>
                                                                                                                <option value="Jalandhar">Jalandhar</option>
                                                                                                                <option value="Bhatinda">Bhatinda</option>
                                                                                                            </select> :
                                                                                                            details.State === "Rajasthan" ?
                                                                                                                <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                    <option >Select City</option>
                                                                                                                    <option value="Jaipur">Jaipur</option>
                                                                                                                    <option value="Jodhpur">Jodhpur</option>
                                                                                                                    <option value="kota">kota</option>
                                                                                                                    <option value="Bhiwadi">Bhiwadi</option>
                                                                                                                    <option value="Bikaner">Bikaner</option>
                                                                                                                </select> :
                                                                                                                details.State === "Sikkim" ?
                                                                                                                    <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                        <option >Select City</option>
                                                                                                                        <option value="Gangtok">Gangtok</option>
                                                                                                                        <option value="Rabdentse">Rabdentse</option>
                                                                                                                        <option value="Pelling">Pelling</option>
                                                                                                                        {/* <option value="Bhiwadi">Bhiwadi</option>
                                                                                                                <option value="Bikaner">Bikaner</option> */}
                                                                                                                    </select> :
                                                                                                                    details.State === "Rajasthan" ?
                                                                                                                        <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                            <option >Select City</option>
                                                                                                                            <option value="Jaipur">Jaipur</option>
                                                                                                                            <option value="Jodhpur">Jodhpur</option>
                                                                                                                            <option value="kota">kota</option>
                                                                                                                            <option value="Bhiwadi">Bhiwadi</option>
                                                                                                                            <option value="Bikaner">Bikaner</option>
                                                                                                                        </select> :
                                                                                                                        details.State === "Tamil Nadu" ?
                                                                                                                            <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                                <option >Select City</option>
                                                                                                                                <option value="Chennai">Chennai</option>
                                                                                                                                <option value="Coimbatore">Coimbatore</option>
                                                                                                                                <option value="Madurai">Madurai</option>
                                                                                                                                <option value="Tiruchirappalli">Tiruchirappalli</option>
                                                                                                                                <option value="Tiruppur">Tiruppur</option>
                                                                                                                            </select> :
                                                                                                                            details.State === "Telangana" ?
                                                                                                                                <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                                    <option >Select City</option>
                                                                                                                                    <option value="Hyderabad">Hyderabad</option>
                                                                                                                                    <option value="Warangal">Warangal</option>
                                                                                                                                    <option value="Nizamabad">Nizamabad</option>
                                                                                                                                    <option value="Khammam">Khammam</option>
                                                                                                                                </select> :
                                                                                                                                details.State === "Tripura" ?
                                                                                                                                    <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                                        <option >Select City</option>
                                                                                                                                        <option value="Agartala">Agartala</option>
                                                                                                                                        <option value="Udaipur">Udaipur</option>
                                                                                                                                        <option value="Dharmanagar">Dharmanagar</option>
                                                                                                                                        <option value="Jampui Hills">Jampui Hills</option>
                                                                                                                                    </select> :

                                                                                                                                    details.State === "Uttar Pradesh" ?
                                                                                                                                        <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                                            <option >Select City</option>
                                                                                                                                            <option value="Kanpur">Kanpur</option>
                                                                                                                                            <option value="Lucknow">Lucknow</option>
                                                                                                                                            <option value="Ghaziabad">Ghaziabad</option>
                                                                                                                                            <option value="Agra">Agra</option>
                                                                                                                                        </select> :
                                                                                                                                        details.State === "Uttarakhand" ?
                                                                                                                                            <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                                                <option >Select City</option>
                                                                                                                                                <option value="Dehradun">Dehradun</option>
                                                                                                                                                <option value="	Haridwar">	Haridwar</option>
                                                                                                                                                <option value="	Roorkee">	Roorkee</option>
                                                                                                                                                <option value="	Haldwani">	Haldwani</option>
                                                                                                                                                <option value="Kashipur">Kashipur</option>
                                                                                                                                            </select> :
                                                                                                                                            details.State === "West Bengal" ?
                                                                                                                                                <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                                                    <option >Select City</option>
                                                                                                                                                    <option value="Dehradun">Dehradun</option>
                                                                                                                                                    <option value="Haridwar">Haridwar</option>
                                                                                                                                                    <option value="Kashipur">Kashipur</option>
                                                                                                                                                    <option value="	Haldwani">Haldwani</option>
                                                                                                                                                    <option value="	Roorkee">Roorkee</option>
                                                                                                                                                </select> :

                                                                                                                                                details.State === "Delhi" ?
                                                                                                                                                    <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                                                        <option >Select City</option>
                                                                                                                                                        <option value="Delhi">Delhi</option>
                                                                                                                                                    </select> :
                                                                                                                                                    details.State === "Chandigarh" ?
                                                                                                                                                        <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                                                            <option >Select City</option>
                                                                                                                                                            <option value="Chandigarh">Chandigarh</option>
                                                                                                                                                        </select> :
                                                                                                                                                        <input type="text" value={Edit.City} className="form-control" disabled />
                    }
                </div>

                <div className="col-md-6">
                    <label className="form-label"><strong>State*</strong></label>
                    <select name="State" id="state" onChange={onChange} className="form-control" required={true} >
                        <option >{Edit.State}</option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                    </select>
                </div>
                <div className="col-md-6 my-2">
                    <label className="form-label"><strong>Zip*</strong></label>
                    <input type="text" className="form-control" defaultValue={Edit.Zip} onChange={onChange} name="Zip" />
                </div>

                <div className="col-md-6 my-2">
                    <label className="form-label"><strong>Referral Code*</strong></label>
                    <input type="text" className="form-control" value={Edit.Refer} onChange={onChange} name="Refer" disabled />
                </div>

                <div className="d-flex justify-content-start">
                    <button className="btn btn-primary btn-sm my-3" style={{ borderRadius: "16px" }} id="DisBtn" disabled>Bank Details</button>
                </div>

                <div className="col-md-12">
                    <label className="form-label"><strong>Account Holder's Name*</strong>{alert}</label>
                    <input type="text" className="form-control" required="true" defaultValue={Edit.AccountName} onChange={onChange} name="AccountName" minLength={2} />
                </div>

                <div className="col-md-12">
                    <label className="form-label"><strong>Account Number*</strong></label>
                    <input type="text" className="form-control" defaultValue={Edit.CAccount} onChange={onChange} name="CAccount" />
                </div>

                <div className="col-md-12">
                    <label className="form-label"><strong>IFSC Code</strong></label>
                    <input type="text" className="form-control" defaultValue={Edit.IFSC} onChange={onChange} name="IFSC" />
                </div>
            </form>
            <div className="d-flex justify-content-center">
                <button className="btn form-btn my-2" onClick={update}>Update</button>
                <Link to="/Admin/Distributer">  <button className="btn form-btn mx-2 my-2" onClick={DeleteDis}>Delete</button></Link>
                <Link to="/Admin/Distributer"><button className="btn form-btn my-2">&larr; Back</button></Link>
            </div>
        </>
    )
}

export default UpdateDis