const initialState = {}
const PInvoice = ( state =initialState , action)=>{
    const PInvoice = action.payload;
    if (action.type === "PRINT")
    {
        // console.log(PInvoice)
        return {...state,...PInvoice }
    }
    else {
    return state;
    }
}
export default PInvoice
