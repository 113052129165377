import React from 'react'
import { Link } from 'react-router-dom'
import { actionCreater } from '../../../State/index'
import { useDispatch } from "react-redux";

function EList(props) {
    const dispatch = useDispatch();

    const details  = {  Name :props.Name  , Email: props.Email ,PhoneNo :props.PhoneNo  ,  Address :props.Address ,Aadhar : props.Aadhar,
         City :props.City  ,  State : props.State ,  Zip : props.Zip  , CAccount : props.CAccount , IFSC : props.IFSC , id : props.id , Products : props.Products , AccountName : props.AccountName};

    const Update =()=>{
      // console.log(details);
        dispatch(actionCreater.Edit(details))
    }
  return (
    <tr>
    <th scope="row" className='text-center'>{props.No}</th>
    <td className='text-center' >{props.Name}</td>
    <td className='text-center'>{props.PhoneNo}</td>
    <td className='text-center' >{props.Email}</td>
    <td className='text-center'>{props.Aadhar}</td>
    <td className='text-center' >{props.Address}</td>
    <td className='text-center'>{props.City}</td>
    <td className='text-center' >{props.State}</td>
    <td className='text-center'>{props.Zip}</td>
    <td className='text-center'>
      <button className="btn btn-success btn-sm" style={{ borderRadius: "16px", backgroundColor: "var(--cardOT-clr)" }} type="button" >Active</button>
      </td>
    <td className='text-center'><Link to="/Admin/Employee/Update"><button className="btn btn-primary btn-sm" onClick={Update} style={{ borderRadius: "16px" }} type="button">Details</button></Link></td>
  </tr>
  )
}

export default EList