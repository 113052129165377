import React, { useState,useEffect} from 'react'


function Profile() {
    const [details, setDetails] = useState([])
    const hostname = process.env.REACT_APP_SERVER

      //                           FETCHING DISTRIBUTER DETAILS
  const DistDetails = async () => {
    const url = `${hostname}/api/Distributer/fetchDetails`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const data = await response.json()
    setDetails(data);
  }

  useEffect(() => {
      DistDetails()
  }, [])
    return (
      
      <div className='container'>
            <h5 style={{ borderBottom: "solid" }} className="my-2"><i class="uil uil-bars">DISTRIBUTER - My Profile</i></h5>

            {
                details.map((element,index) => {
                    return <form className="row" key={index}>
                        <div className="d-flex justify-content-start mb-3">
                            <button className="btn btn-dark btn-sm " style={{ borderRadius: "16px" }} disabled>Personal Details</button>
                        </div>

                        <div className="col-md-6">
                            <label className="form-label"><strong>Name : </strong> {element.username}</label>
                        </div>
                        <div className="col-md-6">
                            <label className="form-label"><strong>PhoneNo : </strong>{element.PhoneNo}</label>
                        </div>
                        <div className="col-md-6 my-3">
                            <label className="form-label"><strong>Email :</strong> {element.Email}</label>
                        </div>

                        <div className="col-md-6 my-3">
                            <label className="form-label"><strong>Address : </strong>{element.Address}</label>
                        </div>

                        <div className="col-md-6">
                            <label className="form-label"><strong>City : </strong>{element.City}</label>

                        </div>
                        <div className="col-md-6 ">
                            <label className="form-label"><strong>State: </strong>{element.State}</label>

                        </div>

                        <div className="col-md-6 my-3">
                            <label className="form-label"><strong>Zip : </strong>{element.Zip}</label>

                        </div>

                        <div className="col-md-6 my-3">
                            <label className="form-label"><strong>Referral Code : </strong>{element.Refer}</label>

                        </div>

                        <div className="d-flex justify-content-start">
                            <button className="btn btn-dark btn-sm my-3" style={{ borderRadius: "16px" }} disabled>Bank Details</button>
                        </div>

                        <div className="col-md-12 my-3">
                            <label className="form-label"><strong>Account Number : </strong>{element.Account}</label>
                        </div>

                        <div className="col-md-12">
                            <label className="form-label"><strong>IFSC Code : </strong>{element.IFSC}</label>
                        </div>
                    </form>
                })
            }
        </div>
    )
}

export default Profile