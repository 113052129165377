import React, { useEffect, useState } from 'react'

function InventoryList() {
  const hostname = process.env.REACT_APP_SERVER
  const [Product, setProduct] = useState([])

  //            FETCHING INVENTORY PRODUCTS
  const ProductDetails = async () => {
    const url = `${hostname}/api/Inventory/fetchProduct`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const prdctDtls = await response.json()
    setProduct(prdctDtls)
  }
  useEffect(() => { ProductDetails() }, [])

  return (
    <>
      <h5 style={{ borderBottom: "solid" }} className="my-2"><i class="uil uil-bars">  DASHBOARD - Inventory Item List</i></h5>
      <div className='container my-3'>
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col" className='text-center' >S.No</th>
              <th scope="col" className='text-center' >Product Name</th>
              <th scope="col" className='text-center' >Quantity</th>
              <th scope="col" className='text-center' >Price</th>
            </tr>
          </thead>
          <tbody>
            {
              Product.map((element, index) => {
                return <tr key={index} >
                  <th scope="row" className='text-center' >{index + 1}</th>
                  <td className='text-center' >{element.ProductName}</td>
                  <td className='text-center' >{element.Quantity}</td>
                  <td className='text-center' >{element.Price}</td>
                </tr>
              })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default InventoryList