import React ,{useEffect} from 'react'
import '../Admin/CSS/Sidebar.css'
import {Link , useLocation} from 'react-router-dom'

function INVSidebar() {
    let location = useLocation();
    useEffect(() => {
    }, [location])
    return (
        <nav id='sidebar'>
                <div className="col-auto col-sm-3 col-sm-2 px-sm-2 px-0">
                    .<img src="" alt='Logo' />
                    <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">

                        <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start align-items-sm-start my-2" >                           
                            <li>
                                <Link to="/Inventory" className={`nav-link ${location.pathname==="/Inventory"?"active":""} align-middle px-1 my-2`} >
                                <i className="uil uil-list-ul">
                                    <span className="ms-1 d-none d-sm-inline">Product List</span> </i> </Link>
                            </li>
                            <li>
                                <Link to="/Inventory/Assign"  className={`nav-link ${location.pathname==="/Inventory/Assign"?"active":""} align-middle px-1 my-2`} >
                                <i className="uil uil-check-square">
                                    <span className="ms-1 d-none d-sm-inline">Assign Products</span></i> </Link>
                            </li>
                        </ul>
                        <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-center"  >
                        <li className="nav-item">
                            <Link to="/" className='nav-link align-middle px-2 my-2'  >
                            <button id='Logout' className='btn btn-primary'><i className="uil uil-signout"> Logout </i> </button> </Link></li> </ul>
                    </div>
                </div>    
                </nav>   
    )
}

export default INVSidebar