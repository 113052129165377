import React,{useEffect,useState,useRef} from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useReactToPrint } from "react-to-print";
import "../CSS/InvoicePrint.css"

function Print() {
  const componentRef = useRef();
  const PInvoice = useSelector((state) => state.PInvoice)
  const hostname = process.env.REACT_APP_SERVER
  const [Product, setProduct] = useState([])
 
 
  //            FETCHING INVENTORY PRODUCTS
   const ProductDetails = async () => {
    const url = `${hostname}/api/Inventory/fetchProduct`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const prdctDtls = await response.json()
  setProduct(prdctDtls)
  }
  useEffect(() => { ProductDetails() },[])

  const Print = useReactToPrint({ content: () => componentRef.current });

 
  return (
    <>
    <div className='container invoice'ref={componentRef}>
      <header>
        <h1 className='text-center fw-bold'>INVOICE</h1>
      </header>

      <div className="row">
        <div className="col CD">

          <div className='row'>
            <div className="flex flex-col">
              <label className="form-label mx-2 fw-bold">Customer's Name :</label>
              <label className="form-label ">{PInvoice.Name}</label>
            </div></div>

            <div className="row">
            <div className="col">
              <label className="form-label mx-2"><strong>Date :</strong></label>
              <label className="form-label mx-2 ">{PInvoice.InDate}</label>
            </div></div>

          <div className='row'>
            <div className="flex flex-col">
              <label className="form-label mx-2 fw-bold">Mobile No. :</label>
              <label className="form-label mx-2 ">{PInvoice.PhoneNo}</label>
            </div></div>

          <div className="row">
            <div className="flex flex-col">
              <label className="form-label mx-2 fw-bold">Email :</label>
              <label className="form-label  ">{PInvoice.Email}</label>
            </div></div>

          <div className='row'>
            <div className="flex flex-col">
              <label className="form-label mx-2 fw-bold">Address :</label>
              <label className="form-label ">{PInvoice.Address}</label>
            </div>
            <label className="form-label mx-2 ">{PInvoice.City}({PInvoice.Zip}) {PInvoice.State} ,{PInvoice.Country}</label>
          </div>

         

        </div>

        <div className="col ComD">
        <div className="row">
              <div className="col">
                <label className="form-label fw-bold">Company Name : </label>
                <label className="form-label mx-2  "> Shopsneaky</label>
              </div>

              <div className="row">
                <div className="col">
                  <label className="form-label fw-bold">GSTIN : </label>
                  <label className="form-label mx-2  ">09ADIPG2245C1ZY</label>
                </div></div>

            <div className="row">
              <div className="col">
                <label className="form-label fw-bold">Invoice Number :</label>
                <label className="form-label mx-2">{PInvoice.InvoiceNo}</label>
              </div></div>
          </div>
         
          <div className="row">
            <div className="col">
              <label className="form-label fw-bold">Contact No.</label>
              <label className="form-label mx-2 ">7017618082</label>
            </div></div>
            <div className="row">
            <div className="flex flex-col">
              <label className="form-label fw-bold">Complaint Description :</label>
              <label className="form-label mx-2 ">{PInvoice.ComplainType}</label>
            </div></div>

            <div className="row">
              <div className="col">
                <label className="form-label fw-bold">Complaint handled by:</label>
                <label className="form-label">{PInvoice.Employee}</label>
              </div></div>

        </div>
      </div>

      <div className='container my-5'>
      <table className='table table-paytyp'style={{boxShadow : "none"}}>
            <tr>
              <td  className='text-center'><strong>Payment Method :</strong>{PInvoice.Payments.PaymentMode}</td>
              <td  className='text-center'><strong>Transaction Number :</strong>{PInvoice.Payments.ReferenceID}</td>
              <td  className='text-center'><strong>Refer :</strong>{PInvoice.Refer}</td>
            </tr>
          </table>
        <table className='itable'>
          <thead>
            <tr>
              <th scope="col" className='text-center'>S.No</th>
              <th scope="col" className='text-center'>Items Used</th>
              <th scope="col" className='text-center'>Quantity</th>
              <th scope="col" className='text-center'>Price / Item</th>
              <th scope="col" className='text-center'>Total Price</th>
            </tr>
          </thead>
          <tbody>
            {
              PInvoice.Products.map((element, index) => {
                return <tr key={index}>
                  <th scope="row" className='text-center'>{index + 1}</th>
                  <td className='text-center'>{element.Name}</td>
                  <td className='text-center'>{element.Quantity}</td>
                  {
                    Product.map((e) => {
                      if (e.ProductName === element.Name) {
                        return <> <td className='text-center'>Rs. {e.Price}</td>
                          <td className='text-center'>Rs.{element.Quantity * e.Price}</td> </>
                      }
                    })}
                </tr>
              })}
          </tbody>
        </table>
        <table className='table table-total'>
            <tr><td  className='text-end'><strong>Total Price : </strong>Rs.{PInvoice.TotalPrice}</td></tr>
              <tr><td  className='text-end'><strong>Total Discount :</strong>Rs.{PInvoice.TotalPrice - PInvoice.DiscountedPrice}</td></tr>
             <tr> <td  className='text-end'><strong>Grand Total :   Rs.{PInvoice.DiscountedPrice}</strong></td></tr>
           </table>

      </div>
    </div>
    <div className="d-flex justify-content-center ">
       <button className='btn btn-primary btn-sm mx-2 my-2' onClick={Print} >Print <i className="uil uil-print"></i></button>
       <Link to="/Admin/Invoice"><button className="btn btn-primary btn-sm my-2">Cancel</button></Link>
        </div>
  </>
  )
}

export default Print