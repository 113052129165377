import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import img1 from './Emp2.jpg'
import './Admin/CSS/Login.css'

function Login() {
  const hostname = process.env.REACT_APP_SERVER
  const [isChecked1, setIsChecked1] = useState(false)
  const [isChecked2, setIsChecked2] = useState(false)
  const [isChecked3, setIsChecked3] = useState(false)
  const [isChecked4, setIsChecked4] = useState(false)
  const [list, setList] = useState("")
  const [credentials, setCredentials] = useState({ username: "", password: " " })
  const history = useNavigate();

  const checkbox1 = (event) => {
    setIsChecked1(event.target.checked)
    if (isChecked1 === false) {
      setList(event.target.value)
    }


  }

  const checkbox2 = (e) => {
    setIsChecked2(e.target.checked)
    if (isChecked2 === false) {
      setList(e.target.value)
    }
  }


  const checkbox3 = (ev) => {
    setIsChecked3(ev.target.checked)
    if (isChecked3 === false) {
      setList(ev.target.value)
    }
  }

  const checkbox4 = (eve) => {
    setIsChecked4(eve.target.checked)
    if (isChecked3 === false) {
      setList(eve.target.value)
    }
  }

  const handleLogin = async () => {
    if (list === "") {
      alert("Please Select Login Type")
    }
    else {
      const response = await fetch(`${hostname}/api/auth/${list}/Login`, {
        method: 'POST',
        headers: {
          "Content-Type": " application/json"
        },
        body: JSON.stringify({ username: credentials.username, password: credentials.password })
      });
      const json = await response.json();
      if (json.success) {
        localStorage.setItem("token", json.authtoken);
        if (list === "Admin") {
          history("/Admin")
        }
        else if (list === "Employee") {
          history("/Employee")
        }
        else if (list === "Inventory") {
          history("/Inventory")
        }
        else if (list === "Distributer") {
          history("/Distributer")
        }
      }

      else {

        alert("Invalid credentials")
      }
    }

  }

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value })
  }



  return (
    <div className='container'>
      <section className="vh-100" style={{ backgroundColor: "var(--primary-color)" }}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-xl-10">
              <div className="card" style={{ borderRadius: "1rem" }}>
                <div className="row g-0">
                  <div className="col-md-6 col-lg-5 d-none d-md-block">
                    <img src={img1} alt="login form" className='img-fluid' style={{ borderRadius: "3rem 0 0 3rem" }} />
                    <div className="card"></div>
                  </div>
                  <div className="col-md-6 col-lg-7 d-flex align-items-center">
                    <div className="card-body p-4 p-lg-5 text-black">

                      <form className='form'>

                        <div className="row">
                          <div className="col">
                          </div>
                          <span className="h2 fw-bold mb-0 text-center">BHAIYAJI</span>
                        </div>

                        <div className="d-flex justify-content-center">
                          <button className="btn btn-dark btn-sm my-2" style={{ borderRadius: "16px" }} disabled>Login</button>
                        </div>

                        <div className="form-outline mb-4">
                          <label className="form-label fw-bold" htmlFor="form2Example17">Username</label>
                          <input type="text" id="form2Example17" name='username' onChange={onChange} className="form-control form-control-lg" />
                        </div>

                        <div className="form-outline mb-4">
                          <label className="form-label fw-bold" htmlFor="form2Example27">Password</label>
                          <input type="password" id="form2Example27" name="password" onChange={onChange} className="form-control form-control-lg" />
                        </div>

                        <label className="form-check-label mx-2" htmlFor="checkbox" id="LoginType"><strong>Admin</strong>
                          <input className="form-check-input mx-1 my-1 " type="checkbox" onChange={checkbox1} value="Admin" checked={isChecked1} />
                        </label>
                        <label className="form-check-label mx-2" htmlFor="checkbox" id="LoginType"><strong>Employee</strong>
                          <input className="form-check-input mx-1 my-1" type="checkbox" onChange={checkbox2} value="Employee" checked={isChecked2} />
                        </label>
                        <label className="form-check-label mx-2" htmlFor="checkbox" id="LoginType" ><strong>Inventory</strong>
                          <input className="form-check-input mx-1 my-1" type="checkbox" onChange={checkbox3} value="Inventory" checked={isChecked3} />
                        </label>
                        <label className="form-check-label mx-2" htmlFor="checkbox" id="LoginType" ><strong>Distributer</strong>
                          <input className="form-check-input mx-1 my-1" type="checkbox" onChange={checkbox4} value="Distributer" checked={isChecked4} />
                        </label>

                        <div className="d-flex justify-content-end">
                          <button className="btn btn-dark btn-lg  my-4" onClick={handleLogin} type="button">Login</button>
                        </div>

                      </form>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>






  )
}

export default Login