import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import InContext from './InContext'


function GenInvoice() {
  const context = useContext(InContext);
  const { result, setInvoice } = context;
  const [details, setDetails] = useState([])
  const [name, setName] = useState([])
  const [quan, setQuan] = useState([])
  const [dtls, setDtls] = useState([])
  const [val, setVal] = useState("")
  const [refer, setRefer] = useState("Nil")
  var [i, setI] = useState(1)
  const [disable, setDisable] = useState(false)
  const [product, setProduct] = useState([])
  const [pList, setPList] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [DList, setDList] = useState([])
  const [q, setQ] = useState([])
  const [n, setN] = useState([])
  const hostname = process.env.REACT_APP_SERVER

  const empDetails = async () => {
    const url = `${hostname}/api/Employee/fetchDetails`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const data = await response.json()
    const Product = data.Products
    const empProduct = Product.filter((Product) => { return Product.Quantity !== "0" })
    setProduct(empProduct)
    setPList(empProduct)
  }


  //               FETCHING DISTRIBUTER
  const fetchDistributer = async () => {
    const url = `${hostname}/api/Admin/Distributer/fetchDistributer`

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const Dist = await response.json()
    setDList(Dist)
  }

  useEffect(() => { fetchDistributer() }, [])
  useEffect(() => { empDetails() }, [])


  var array3 = details.map(function (e, i) {
    return Object.assign(e, dtls[i]);
  });

  const FInvoice = {
    Name: result.Name, Email: result.Email, PhoneNo: result.PhoneNo, Address: result.Address, City: result.City, State: result.State,
    Country: result.Country, Zip: result.Zip, Refer: refer, TicketID : result.id, Landmark: result.Landmark, ComplainType: result.ComplainType,
    empAssign: result.empAssign, TicketNo: result.TicketNo
  };

  const Invoice = () => {
    if (disabled === true) {
      setDetails([...details, ...name])
      setDtls([...dtls, ...quan])
      setInvoice({ ...FInvoice, Product: array3 })
    }
    else {
      alert("Please finish adding items first.")
    }
  }

  const handleAdd = async() => {
      if (q > 0) {
        const obj = pList.find((product) => product.Name == n)
      if(obj.Quantity >= q)
      {
      i = i + 1;
      setI(i)
      setDetails([...details, ...name])
      setDtls([...dtls, ...quan])
      let result = product.filter((datasetObj) => name.every((criteria) => criteria[Object.keys(criteria)[0]] !== datasetObj[Object.keys(criteria)[0]]));
      setProduct(result)
      setQuan([])
      setName([])
      }
     else{
      alert("Used product quantity should be lesser than Product Quantity in Inventory")
     }
    }
    else {
      alert("Quantity cannot be empty/0 or in negative")
    }
  }

  const handleFinish = () => { setDisabled(true) }

  const handleCancel = () => {
    setQuan([])
    setName([])
    setDisabled(false);
  }

  //  OnChange function for Select 
  const onchange = (e) => {
    setName([{ Name: e.target.value }])
    setN(e.target.value)
  }
  //  Onchange function for Quantity
  const onchange2 = (e) => {
    if (e.target.value === "") {
      setQuan([{ Quantity: 0 }])
    }
    else {
      setQuan([{ Quantity: e.target.value }])
      setQ(e.target.value);
    }
  }

  //  Onchange function for Referral
  const onChng = (e) => {
    setVal(e.target.value)
  }

  const Validate = () => {
    DList.map((element) => {
      if (element.Refer === val) {
        setRefer(val)
        setDisable(true)
        alert("Validation Successfull")
      }
    })
  }

  const removeProduct = (Name) => {
    if (disabled === false) {
      const newDetails = details.filter((details) => { return details.Name !== Name })
      setDetails(newDetails)
      const addProduct = [{ "Name": Name, "Quantity": 1 }]
      setProduct(product.concat(addProduct))
    }
    else {
      alert("Cannot Remove Product After Finishing Adding. ")
    }

  }

  return (
    <>

      <div className='container my-3' style={{ border: "solid" }}>
        <header>
          <h3 className='text-center fw-bold my-3'>Fill Invoice Details</h3>
        </header>
        <div>
          <label className=' fw-bold input mx-2' htmlFor="Customer Name" >Customer Name </label>
          <input type="text" className='invoice-input' defaultValue={result.Name} readOnly />
          <label className=' fw-bold input mx-2'> Email </label>
          <input type="text" className='invoice-input' defaultValue={result.Email} readOnly />
          <label className=' fw-bold input mx-2'> Mobile No. </label>
          <input type="text" className='invoice-input' defaultValue={result.PhoneNo} readOnly />
          <div>
            <label className='input fw-bold mx-2'>Address</label>
            <input className=" invoice-input address text-center" type="text" defaultValue={result.Address} readOnly />
            <input className="invoice-input text-center" type="text" defaultValue={result.City} readOnly />
            <input className="invoice-input text-center" type="text" defaultValue={result.State} readOnly />
            <input className="invoice-input text-center" type="text" defaultValue={result.Zip} readOnly />
            <input className="invoice-input text-center" type="text" defaultValue={result.Country} readOnly />
          </div>
        </div>


        <div className='container my-5' >
          <p className='text-center fw-bold'>ADD PRODUCTS</p>
          <table className='table add-product'>
            <tbody>
              <tr key={i} >
                <td>
                  <div className="form-outline">
                    <select className="form-select" onChange={onchange} name="Name" aria-label="Default select example" disabled={disabled}>
                      <option className='text-center' selected>Select Product</option>
                      {
                        product.map((element) => { return <option className='text-center' value={element.Name} >{element.Name} </option> })
                      }
                    </select></div>
                </td>
                <td className='text-center'><input className="text-center"  onChange={onchange2} placeholder='Quantity' name="Quantity" type="number" disabled={disabled} /></td>
                <td className='text-center'><button className='btn btn-primary btn-sm' onClick={handleAdd} disabled={disabled} ><i class="uil uil-plus"></i></button></td>
              </tr>
            </tbody>
          </table>

          <table className='table add-product-mobile'>
            <tbody>
              <tr>
                <th scope="col" className='text-center'>Product List</th>
                <td>
                  <div className="form-outline">
                    <select className="form-select" onChange={onchange} name="Name" aria-label="Default select example" disabled={disabled}>
                      <option className='text-center' selected>Select Product</option>
                      {
                        product.map((element) => { return <option className='text-center' value={element.Name} >{element.Name} </option> })
                      }
                    </select></div>
                </td>


              </tr>
            </tbody>
            <tbody>
              <tr key={i} >
                <th scope="col" className='text-center'>Quantity</th>
                <td className='text-center'><input className="text-center" onChange={onchange2} min="0" placeholder='Quantity' name="Quantity" type="number" disabled={disabled} /></td>
              </tr>
            </tbody>
            <tbody>
              <td className='text-center'></td>
              <td className='text-center'><button className='btn btn-sm' style={{ background: "#0d6efd", color: "white" }} onClick={handleAdd} disabled={disabled} >Add</button></td>
            </tbody>

          </table>


          {details.length > 0 ? <table className='table'>
            <thead>
              <tr>
                <th scope="col" className='text-center'>Product Used</th>
                <th scope="col" className='text-center'>Quantity</th>
                <th scope="col" className='text-center'>Remove Product</th>
              </tr>
            </thead>
            <tbody>
              {
                details.map((e, i) =>
                  <tr key={i} >
                    <td className='text-center'>{e.Name} </td>
                    <td className='text-center'>{e.Quantity}</td>
                    <td className='text-center'><button className='btn btn-primary btn-sm' onClick={() => removeProduct(e.Name)}><i className="uil uil-trash-alt"></i></button></td>
                  </tr>
                )}
            </tbody>
          </table> :
            <div className='container'>
              <p className='text-center'>No Products have been added yet.</p></div>}


          <div className="d-flex justify-content-end">
            {disabled === false ? <button className='btn btn-primary btn-sm mx-2 my-2' onClick={handleFinish} >Finish Adding </button> : <button className='btn btn-primary btn-sm mx-2 my-2' onClick={handleCancel} >Cancel</button>}
          </div>
          <div>
            <label className='input fw-bold mx-2'>Referral Code</label>
            <input type="text" className='invoice-input'  onChange={onChng} disabled={disable} />
            <button className='btn btn-primary btn-sm mx-2' id='validate' onClick={Validate} disabled={disable}>Validate</button>
          </div>

          <div className="d-flex justify-content-center ">
            {disabled === true ? <Link to="/Employee/Invoice"><button className='btn btn-primary btn-sm mx-2 my-2' onClick={Invoice} style={{ borderRadius: "16px" }} >GENERATE</button></Link> : <button className='btn btn-primary btn-sm mx-2 my-2' onClick={Invoice} style={{ borderRadius: "16px" }} >GENERATE</button>}
            <Link to="/Employee"> <button className="btn btn-primary btn-sm mx-2 my-2" style={{ borderRadius: "16px" }} type="button">Cancel</button></Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default GenInvoice