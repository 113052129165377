import Login from './Components/Login';
import './App.css'
import { BrowserRouter as HashRouter, Routes, Route } from 'react-router-dom';
import Sidebar from './Components/Admin/Sidebar';
import AdminCard from './Components/Admin/AdminCard';
import OpenTickets from './Components/Admin/Tickets/Open';
import TotalTickets from './Components/Admin/Tickets/Total';
import PendingTickets from './Components/Admin/Tickets/Pending';
import Close from './Components/Admin/Tickets/Close';
import EmployeeList from './Components/Admin/Employee/EmployeeList';
import EmployeeForm from './Components/Admin/Employee/EmployeeForm';
import Products from './Components/Inventory/Products';
import INVSidebar from './Components/Inventory/INVSidebar';
import AssignItems from './Components/Inventory/AssignItems';
import AddTicket from './Components/Admin/Tickets/AddTicket';
import EmpTicket from './Components/Employee/EmpTicket';
import Invoice from './Components/Employee/Invoice';
import DList from './Components/Admin/Distributer/DList';
import AddDistributer from './Components/Admin/Distributer/AddDistributer';
import InventoryList from './Components/Admin/Inventory/InventoryList';
import EmpCard from './Components/Employee/EmpCard';
import ClosedTickets from './Components/Employee/ClosedTicket';
import InvoiceList from './Components/Admin/Invoice/InvoiceList';
import GenInvoice from './Components/Employee/GenInvoice';
import Contxt from "./Components/Employee/Contxt"
import UpdateEmp from './Components/Admin/Employee/UpdateEmp';
import Print from './Components/Admin/Invoice/Print';
import UpdateDis from './Components/Admin/Distributer/UpdateDis';
import Distributer from './Components/Distributor/Distributer';
import DistSidebar from './Components/Distributor/DistSidebar';
import Profile from './Components/Distributor/Profile';
import EmpProfile from './Components/Employee/EmpProfile';
import PrintInvoice from './Components/Employee/PrintInvoice';
import Navbar from './Components/Employee/Navbar';
import Payments from './Components/Admin/Distributer/Payments';
import DisPayments from './Components/Distributor/DisPayments';
import EmpInventory from './Components/Admin/Employee/EmpInventory';
import TcktInvoice from './Components/Admin/Tickets/TcktInvoice';
import DisTcktInvoice from './Components/Distributor/DisTcktInvoice';
import DRefTransaction from './Components/Admin/Distributer/DRefTansactions';



function App() {
  return (
    <>
      <HashRouter>
        {/* <Fetch /> */}

        {/* Routes for Login */}
        <Routes> <Route exact path="/" element={<Login />} /> </Routes>

        {/* Routes for Admin Dashboard*/}
        < div className="row">
          <div className='sidebar'>
            <Routes> <Route exact path="/Admin/*" element={<Sidebar />} />
            </Routes>
          </div>

          <div className='Admin'>
            <Routes>
              <Route exact path="/Admin/*" element={<AdminCard />}>
                <Route exact path="" element={<TotalTickets />} />
                <Route exact path="OpenTickets" element={<OpenTickets />} />

                <Route exact path="ClosedTickets" element={<Close />} />

                <Route exact path="PendingTickets" element={<PendingTickets />} />
              </Route>
              <Route exact path="Admin/ClosedTickets/Invoice" element={<TcktInvoice />} />
              <Route exact path="/Admin/AddTicket" element={<AddTicket />} />
              <Route exact path="/Admin/Employee" element={<EmployeeList />} />
              <Route exact path="/Admin/Employee/AddNew" element={<EmployeeForm />} />
              <Route exact path="/Admin/Employee/Update" element={<UpdateEmp />} />
              <Route exact path="/Admin/Employee/Inventory" element={<EmpInventory />} />
              <Route exact path="/Admin/Distributer" element={<DList />} />
              <Route exact path="/Admin/Distributer/AddNew" element={<AddDistributer />} />
              <Route exact path="/Admin/Distributer/Details" element={<UpdateDis />} />
              <Route exact path="/Admin/Distributer/Details/Payments" element={<Payments />} />
              <Route exact path="/Admin/Distributer/Details/RefPayments" element={<DRefTransaction/>} />
              <Route exact path="/Admin/Inventory" element={<InventoryList />} />
              <Route exact path="/Admin/Invoice" element={<InvoiceList />} />
              <Route exact path="/Admin/Invoice/Print" element={<Print />} />
            </Routes>

          </div>
        </div>

        {/* Routes for Employee Dashboard*/}
        <Contxt>
          <Routes> <Route exact path="/Employee/*" element={<Navbar />} /></Routes>
          <Routes>
            <Route exact path="/Employee/*" element={<EmpCard />}>
              <Route exact path="" element={<EmpTicket />} />
              <Route exact path="Closed" element={<ClosedTickets />} />
            </Route>
            <Route exact path="Employee/GenInvoice" element={<GenInvoice />} />
            <Route exact path="Employee/Invoice" element={<Invoice />} />
            <Route exact path="Employee/Profile" element={<EmpProfile />} />
            <Route exact path="/Employee/Closed/Print" element={<PrintInvoice />} />
          </Routes>
        </Contxt>


        {/* Routes for Inventory Dashboard*/}
        <div className="row">
          <div className='sidebar'>
            <Routes>
              <Route exact path="/Inventory/*" element={<INVSidebar />} /> </Routes> </div>
          <div className='Admin'>
            <Routes>
              <Route exact path="/Inventory/*" element={<Products />} ></Route>
              <Route exact path="Inventory/Assign" element={<AssignItems />} />
            </Routes>
          </div> </div>

        {/* Routes for Distributer Dashboard*/}
      
          <div className="row">
            <div className='sidebar'>
              <Routes><Route exact path="/Distributer/*" element={<DistSidebar />} /></Routes>
            </div>
            <div className='Admin'>
              <Routes>
                <Route exact path="/Distributer" element={<Distributer />} />
                <Route exact path="/Distributer/TicketInvoice" element={<DisTcktInvoice />} />
                <Route exact path="Distributer/Profile" element={<Profile />} />
                <Route exact path="Distributer/Transactions" element={<DisPayments />} />
              </Routes>

            </div> </div>
        
      </HashRouter>

    </>



  );
}

export default App;
