const initialState = []
const OpenTckt = (state = initialState, action) => {

    if (action.type === "FETCH_OT_DETAILS") {

        const Open = action.payload
        // console.log({ Open })
        return [...state, ...Open]
    }
    else if (action.type === "FETCH_AFTER_ASSIGN") {
        const Open = action.payload
        // console.log(Open._id)
        const result = state.filter((state) => { return state._id !== Open._id })
        // console.log(result);
        return result;

    }
    else {
        // console.log(state);
        return state
       
    }
}
export default OpenTckt
