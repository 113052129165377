const initialState = []
const empReducer = (state = initialState, action) => {
    const empdetails = action.payload;
    if (action.type === "FETCH_EMP_DETAILS") {
        return [...state, ...empdetails]
    }
    else
        if (action.type === "UPDATE_EMP_DETAILS") {
            const i = state.findIndex((element) =>element._id === empdetails._id)
            state[i] = empdetails;
            console.log(state);
            return state;
        }
        else
            if (action.type === "DELETE_EMP") {
                const result = state.filter((state) => { return state._id !== empdetails._id })
                return [...result]
            }
            else {
                return state;
            }
}
export default empReducer

