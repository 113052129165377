
export const EmployeeDetails = (empdetails) => {
    return (dispatch) => {
        dispatch({
            type: "FETCH_EMP_DETAILS",
            payload: empdetails
        })
    }
}

export const ProductDetails = (prdctDtls) => {
    return (dispatch) => {
        dispatch({
            type: "FETCH_PRDCT_DETAILS",
            payload: prdctDtls
        })
    }
}

export const OpenTckt = (Open) => {
    return (dispatch) => {
        dispatch({
            type: "FETCH_OT_DETAILS",
            payload: Open
        })
    }
}
export const CloseTckt = (Close) => {
    return (dispatch) => {
        dispatch({
            type: "FETCH_CT_DETAILS",
            payload: Close
        })
    }
}
export const PendingTckt=(Pending) => {
    return (dispatch) => {
        dispatch({
            type: "FETCH_PT_DETAILS",
            payload: Pending
        })
    }
}
export const TotalTckt=(Total) => {
    return (dispatch) => {
        dispatch({
            type: "FETCH_TT_DETAILS",
            payload: Total
        })
    }
}

export const Distributer = (Dist) => {
    return (dispatch) => {
        dispatch({
            type: "FETCH_DISTRIBUTER_DETAILS",
            payload: Dist
        })
    }
}

export const empPending=(EPending) => {
    return (dispatch) => {
        dispatch({
            type: "FETCH_EPT_DETAILS",
            payload: EPending
        })
    }
}

export const Invoice=(Invoice) => {
    return (dispatch) => {
        dispatch({
            type: "FETCH_INVOICE_DETAILS",
            payload: Invoice
        })
    }
}

export const Assign=(Open) => {
    return (dispatch) => {
        dispatch({
            type: "FETCH_AFTER_ASSIGN",
            payload: Open
        })
    }
}

export const Edit=(Edit) => {
    return (dispatch) => {
        dispatch({
            type: "EDIT",
            payload: Edit
        })
    }
}

export const PrintInvoice=(PInvoice) => {
    return (dispatch) => {
        dispatch({
            type: "PRINT",
            payload: PInvoice
        })
    }
}


export const UpdateEmp=(empdetails) => {
    return (dispatch) => {
        dispatch({
            type: "UPDATE_EMP_DETAILS",
            payload: empdetails
        })
    }
}

export const UpdateDis=(Dist) => {
    return (dispatch) => {
        dispatch({
            type: "UPDATE_DISTRIBUTER_DETAILS",
            payload: Dist
        })
    }
}

export const DeleteDis=(Dist) => {
    return (dispatch) => {
        dispatch({
            type: "DELETE_DIST",
            payload: Dist
        })
    }
}

export const UpdatePrdct=(prdctDtls) => {
    return (dispatch) => {
        dispatch({
            type: "UPDATE_PRDCT_DETAILS",
            payload: prdctDtls
        })
    }
}


export const DeleteEmp=(empdetails) => {
    return (dispatch) => {
        dispatch({
            type: "DELETE_EMP",
            payload:empdetails
        })
    }
}

export const DeletePrdct=(prdctDtls) => {
    return (dispatch) => {
        dispatch({
            type: "DELETE_PRDCT",
            payload: prdctDtls
        })
    }
}