import React from 'react'
import { actionCreater } from '../../../State/index'
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';

function DisList(props) {
  const dispatch = useDispatch();
  const details  = {  Name :props.Name  , Email: props.Email , PhoneNo :props.PhoneNo  ,  Address :props.Address , AccountName : props.AccountName,
    City :props.City  ,  State : props.State ,  Zip : props.Zip  , CAccount : props.CAccount , IFSC : props.IFSC ,id : props.id ,Refer : props.Refer , Payments : props.Payments};
  
    const handleDetails=()=>{
      dispatch(actionCreater.Edit(details))
    }
    
  return (
    <tr>
    <th scope="row" className='text-center'>{props.No}</th>
    <td className='text-center'>{props.Name}</td>
    <td className='text-center'>{props.Refer}</td>
    <td className='text-center'>{props.Email}</td>
    <td className='text-center'>{props.PhoneNo}</td>
    <td className='text-center'>{props.Address}</td>
    <td className='text-center'>{props.City}</td>
    <td className='text-center'>{props.State}</td>
    <td className='text-center'>{props.Zip}</td>
    <td className='text-center'>
    <Link to ="/Admin/Distributer/Details"> 
     <button className="btn btn-primary btn-sm" onClick={handleDetails} style={{ borderRadius: "16px" }} type="button">Details</button>
     </Link>
    </td>
  </tr>
  )
}

export default DisList