const initialState = []
const CloseTckt = ( state =initialState , action)=>{
  
 if (action.type === "FETCH_CT_DETAILS")
   
 {        
        const Close = action.payload 
        return [...state , ...Close]
    }
    else
    {
    // console.log("Not Done");
    return state;
    }
}
export default CloseTckt