import React, { useState, useEffect } from 'react'

function PendingTickets() {
  const [mobile, setMobile] = useState(0)
  const [searchRes, setSearchRes] = useState([])
  const [showSearch, setShowSearch] = useState(false)
  const [sort, setSort] = useState(false)
  const [pendingTckt, setPendingTckt] = useState([])
  // console.log(PendingTckt);

  //                 SORT PENDING TICKETS
  const sortPT = async () => {
    const url = "https://www.complaint-ticketing-api.dkernels.com/api/Admin/sortPT"
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const Pending = await response.json()
    setPendingTckt(Pending)
    setSort(true);
  }

  const Cancelsorting = async () => {
    setSort(false);
    const url = "https://www.complaint-ticketing-api.dkernels.com/api/Admin/fetchPndg"
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const Pending = await response.json()
    setPendingTckt(Pending)
  }


  const searchTicket = async () => {
    setShowSearch(true)
    const url = `https://www.complaint-ticketing-api.dkernels.com/api/Admin/findPTByPhoneNo?PhoneNo=${mobile}`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const result = await response.json()
    setSearchRes(result);
  }

  //                FETCHING PENDING TICKETS
  const fetchPending = async () => {
    const url = "https://www.complaint-ticketing-api.dkernels.com/api/Admin/fetchPndg"
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const Pending = await response.json()
    // console.log(Pending);
    setPendingTckt(Pending)
  }

  const onChang = (e) => {
    setMobile(e.target.value)
  }
  const handleCancel = () => {
    setShowSearch(false)
  }
  useEffect(() => { fetchPending() }, [])

  return (
    <div>
      <div>
        <input type="text" placeholder='Mobile Number' id='Search' className='invoice-input mx-1' onChange={onChang} />
        <button className='btn btn-primary btn-sm mx-2 my-1' onClick={searchTicket} style={{ backgroundColor: " var(--primary-color)" }} >Search</button>
        {showSearch === true ? <button className='btn btn-primary btn-sm mx-2' onClick={handleCancel} >Cancel</button> : <button className='btn btn-primary btn-sm mx-2 my-1' style={{ backgroundColor: " var(--primary-color)" }} onClick={sortPT} >Sort By Pin</button>}
        {sort === true ? <button className='btn btn-primary btn-sm mx-2' style={{ backgroundColor: " var(--primary-color)" }} onClick={Cancelsorting} >Cancel</button> : <></>}
      </div>
      <div className='container-ticket'>
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col" className='text-center'>Ticket Number</th>
              
              <th scope="col" className='text-center'>Pending Ticket Date</th>
              <th scope="col" className='text-center'>Open Ticket Date</th>
              <th scope="col" className='text-center'>Full Name</th>
              <th scope="col" className='text-center'>Complaint Description</th>
              <th scope="col" className='text-center'>Email</th>
              <th scope="col" className='text-center'>Contact Number</th>
              {/* <th scope="col" className='text-center'>Address</th> */}
              {/* <th scope="col" className='text-center'>Landmark</th> */}
              <th scope="col" className='text-center'>City</th>
              <th scope="col" className='text-center'>State</th>
              <th scope="col" className='text-center'>Zip</th>
              {/* <th scope="col" className='text-center'>Country</th> */}
              <th scope="col" className='text-center'>Status</th>
              <th scope="col" className='text-center'>Assinged To</th>
            </tr>
          </thead>
          <tbody>
            {showSearch === false ? pendingTckt.map((element, index) => {
              return <tr key={index} >
                <th scope="row" className='text-center'>{element.TicketNo}</th>
                <th scope="row" className='text-center'>{element.ptDate}</th>
                <th scope="row" className='text-center'>{element.otDate}</th>
                <td className='text-center'>{element.Name}</td>
                <td className='text-center'>{element.ComplainType}</td>
                <td className='text-center'>{element.Email}</td>
                <td className='text-center'>{element.PhoneNo}</td>
                {/* <td className='text-center'>{element.Address}</td> */}
                {/* <td className='text-center'>{element.Landmark}</td> */}
                <td className='text-center'>{element.City}</td>
                <td className='text-center'>{element.State}</td>
                <td className='text-center'>{element.Zip}</td>
               

                <td className='text-center'>
                  <button className="btn  btn-sm" style={{  backgroundColor:" var(--cardPT-clr)" , borderRadius: "16px", color: "white" }} type="button">{element.Status}</button>
                </td>
                 <th className='text-center'>{element.assingedTo}</th>
              </tr>
            }) : searchRes.map((element, index) => {
              return <tr key={index} >
                <th scope="row" className='text-center'>{element.TicketNo}</th>
                <th scope="row" className='text-center'>{element.ptDate}</th>
                <th scope="row" className='text-center'>{element.otDate}</th>
                <td className='text-center'>{element.Name}</td>
                <td className='text-center'>{element.ComplainType}</td>
                <td className='text-center'>{element.Email}</td>
                <td className='text-center'>{element.PhoneNo}</td>
                {/* <td className='text-center'>{element.Address}</td> */}
                {/* <td className='text-center'>{element.Landmark}</td> */}
                <td className='text-center'>{element.City}</td>
                <td className='text-center'>{element.State}</td>
                <td className='text-center'>{element.Zip}</td>
                {/* <td className='text-center'>{element.Country}</td> */}
                <td className='text-center'><button className="btn btn-sm" style={{ backgroundColor:" var(--cardPT-clr)",borderRadius: "16px", color: "white" }} type="button">{element.Status}</button>
                </td>
                <th className='text-center'>{element.assingedTo}</th>
              </tr>
            })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PendingTickets