import { combineReducers } from "redux";
import empReducer from "./empReducers";
import prdctReducer from "./prdctReducer";
import OpenTckt from "./OpenTckt";
import PendingTckt from "./PendingTckt";
import CloseTckt from "./CloseTckt";
import DList from "./DList";
import empPending from './empPending'
import Invoice from "./Invoice";
import Edit from "./Edit"
import PInvoice from "./PInvoice";
import  TotalTckt  from "./TotalTckt";

const rootReducer = combineReducers(
    {
    empdetails : empReducer,
    prdctDtls : prdctReducer,
    Open : OpenTckt,
    Close : CloseTckt,
    Pending : PendingTckt,
    Dist : DList,
    EPending : empPending,
    Invoice : Invoice,
    Edit : Edit,
    PInvoice : PInvoice,
    Total : TotalTckt
})
export default rootReducer  