import React from 'react'
import { Link } from 'react-router-dom'
import { actionCreater } from '../../../State/index'
import { useDispatch } from "react-redux";


function IList(props) {
   const dispatch = useDispatch();

  const Print = {
    No: props.No, Name: props.Name, ComplainType: props.ComplainType, Email: props.Email, PhoneNo: props.PhoneNo, Address: props.Address, Landmark: props.Landmark,
    City: props.City, Aadhar: props.Aadhar, State: props.State, Zip: props.Zip, Country: props.Country, Products: props.Products, InDate: props.InDate,
     TotalPrice: props.TotalPrice , DiscountedPrice : props.DiscountedPrice, Refer:props.Refer ,Payments : props.Payments
  }


  const handlePrint = () => {
    dispatch(actionCreater.PrintInvoice(Print))
  }
  return (
 
      <tr key = {props.index}>
        <th scope="row" className='text-center'>{props.No}</th>
        <td className='text-center' >{props.Name}</td>
        <td className='text-center' >{props.PhoneNo}</td>
        <td className='text-center' >{props.InvoiceNo}</td>
        <td className='text-center' >{props.InDate}</td>
        <td className='text-center'>
     <Link to = "/Admin/Invoice/Print"> <button className="btn btn-primary btn-sm" onClick={handlePrint} style={{ borderRadius: "16px" }} type="button">View <i class="uil uil-invoice"></i></button></Link>
        </td></tr>
  )
}

export default IList