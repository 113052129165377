const initialState = []
const TotalTckt = ( state =initialState , action)=>{
  
 if (action.type === "FETCH_TT_DETAILS")
   
 {        
        const Total = action.payload 
        console.log(Total)
        return [...state , ...Total]
    }
    else
    {
    // console.log("Not Done");
    return state;
    }
}
export default TotalTckt