import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
const hostname = process.env.REACT_APP_SERVER


function Products() {
  const history = useNavigate();
  const refClose = useRef(null);
  const refClose1 = useRef(null);
  const [product, setProduct] = useState([])
  const [details, setDetails] = useState({})
  const [pName, setPName] = useState({})
  const set = (PName) => { setPName(PName) }


  const ProductDetails = async () => {
    const url = `${hostname}/api/Inventory/fetchProduct`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const prdctDtls = await response.json()
    setProduct(prdctDtls)
  }

  useEffect(() => {
    if (localStorage.getItem('token')) {
      ProductDetails()
    }
    else {
      history('/')
    }
  })




  //                                                ADDING NEW PRODUCT TO INVENTORY

  const addProduct = async () => {
    const url = `${hostname}/api/Inventory/addProduct`
    await fetch(url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ProductName: details.ProductName, Quantity: details.Quantity, Price: details.Price })
      });
    refClose.current.click();
  }

  //                                                 UPDATE QUANTITY OF INVENTORY

  const updateQuantity = async () => {
    if (details.Quantity !== undefined) {

      const url = `${hostname}/api/Inventory/updateQuantity`
      await fetch(url,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ProductName: pName, Quantity: details.Quantity })
        });
      ProductDetails()
      refClose1.current.click();
    }
    else {
      alert("Quantity cannot be empty")
    }
  }

  //                                                     DELETE PRODUCT

  const DeletePrdct = async (id) => {
    const url = `${hostname}/api/Inventory/deleteProduct/${id}`
    await fetch(url,
      {
        method: 'Delete',
        headers: { 'Content-Type': 'application/json' }
      });
    alert("Product Deleted Successfully");
  }

  const onChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value })
  }

  return (
    <>

      {/*                               MODAL FOR UPDATING PRODUCT QUANTITY                                                   */}

      <div className="modal fade" id="updateQuantity" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Add Product</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div className="modal-body">
              <div className="form-outline mb-4">
                <label className="form-label"><strong>Quantity</strong></label>
                <input type="number" onChange={onChange} name="Quantity" required={true} className="form-control" />
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={updateQuantity} >Update</button>
              <button type="button" ref={refClose1} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>


      {/*                                               MODAL FOR ADDING NEW PRODUCT                                          */}

      <div className="modal fade" id="addProduct" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Add Prodcut</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div className="modal-body">
              <div className="form-outline mb-4">
                <label className="form-label"><strong>Product Name</strong></label>
                <input type="text" onChange={onChange} name="ProductName" className="form-control" />
              </div>
              <div className="form-outline mb-4">
                <label className="form-label"><strong>Quantity</strong></label>
                <input type="number" onChange={onChange} name="Quantity" className="form-control" />
              </div>
              <div className="form-outline mb-4">
                <label className="form-label"><strong>Price</strong></label>
                <input type="number" onChange={onChange} name="Price" className="form-control" />
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={addProduct} >Add</button>
              <button type="button" ref={refClose} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <h5 style={{ borderBottom: "solid" }} className="my-2"><i className="uil uil-bars"> INVENTORY - Item List</i> </h5>
      <div className="d-flex justify-content-end ">
        <button type="button" className="btn btn-dark btn-sm" id="Ad" data-bs-toggle="modal" style={{ borderRadius: "16px" }} data-bs-target="#addProduct">Add Product</button>
      </div>

      {/*                                                   PRODUCT LIST                                                             */}
      <div className='container-ticket my-3'>
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col" className='text-center' >S.No</th>
              <th scope="col" className='text-center' >Product Name</th>
              <th scope="col" className='text-center' >Quantity</th>
              <th scope="col" className='text-center' >Details</th>
              <th scope="col" className='text-center' >Delete</th>
            </tr>
          </thead>
          <tbody>
            {
              product.map((element, index) =>
                <tr key={index} >
                  <th scope="row" className='text-center' >{index + 1}</th>
                  <td className='text-center' >{element.ProductName}</td>
                  <td className='text-center' >{element.Quantity}</td>
                  <td className='text-center' >
                    <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" onClick={() => set(element.ProductName)} style={{ borderRadius: "16px" }} data-bs-target="#updateQuantity">Update Quantity</button>
                  </td>
                  <td className='text-center' >
                    <button className="btn btn-dark btn-sm" style={{ borderRadius: "16px" }} onClick={() => DeletePrdct(element._id)} type="button">Delete</button>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>

    </>

  )
}

export default Products