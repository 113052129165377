import React,{useEffect,useState} from 'react'
import {Link} from 'react-router-dom'
// import {useSelector} from "react-redux"
import DisList from './DisList';

function DList() {
  const [DList, setDList] = useState([])
  const hostname = process.env.REACT_APP_SERVER
  
  //               FETCHING DISTRIBUTER
    const fetchDistributer = async () => {
      const url = `${hostname}/api/Admin/Distributer/fetchDistributer`
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem('token')
        }
      })
      const Dist = await response.json()
      setDList(Dist)
    }
    useEffect(() => { fetchDistributer() },[])
  
  return (
    <div className='container my-3'>
       <h5 style={{ borderBottom : "solid" }} className="my-2"><i className="uil uil-bars">DASHBOARD - Distributer List</i></h5>
       <div className="d-flex justify-content-end">
    <Link to="AddNew"> <button className="btn btn-dark btn-sm" id = "Ad" type="button">Add Distributer</button></Link>
    </div>
    <div className='conatiner-dislist'>
    <table className="table table-bordered table-striped my-3">
      <thead>
        <tr>
          <th scope="col" className='text-center'>S.No</th>
          <th scope="col" className='text-center'>Full Name</th>
          <th scope="col" className='text-center'>Referral Code</th>
          <th scope="col" className='text-center'>Email</th>
          <th scope="col" className='text-center'>Phone No.</th>
          <th scope="col" className='text-center'>Address</th>
          <th scope="col" className='text-center'>City</th>
          <th scope="col" className='text-center'>State</th>
          <th scope="col" className='text-center'>Zip</th>
          <th scope="col" className='text-center'>Details</th>
     </tr>
      </thead>
      <tbody>
        {
        DList.map((element,index)=>{
        return <DisList key = {index} No = {index+1} Name= {element.username} Refer = {element.Refer} id ={element._id} Email = {element.Email} PhoneNo ={element.PhoneNo} Address = {element.Address}
         City = {element.City} State ={element.State} Zip= {element.Zip}  CAccount = {element.CAccount}  IFSC = {element.IFSC} Payments = {element.Payments} AccountName = {element.AccountName}/>
         })
          }
      </tbody>
    </table>
    </div>
  </div>
  )
}

export default DList