const initialState = []


const DList = (state = initialState, action) => {
    const Dist = action.payload;
    if (action.type === "FETCH_DISTRIBUTER_DETAILS") {
        return [...state, ...Dist]
    }
    else if (action.type === "UPDATE_DISTRIBUTER_DETAILS") {
        const i = state.findIndex((element) => element._id === Dist._id)
        // console.log(i);
        state[i] = Dist;
        return state;
    }
    else
        if (action.type === "DELETE_DIST") {
            const result = state.filter((state) => { return state._id !== Dist._id })
            return [...result]
        }
        else {
            return state;
        }
}
export default DList

