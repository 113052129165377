import React, { useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';

function DRefTransactions() {

  const history = useNavigate();
  let Refer = ""
  const [myReferInv, setMyReferInv] = useState([])
  const Edit = useSelector((state) => state.Edit)
  const hostname = process.env.REACT_APP_SERVER
 

  //                    FETCHING REFERALL INVOICE
  
  const fetchRefer = async () => {
    Refer = Edit.Refer
    const url = `${hostname}/api/Admin/fetchRefer?Refer=${Refer}`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const Dist = await response.json()
    setMyReferInv(Dist)
  }

useEffect(() => {
    if (localStorage.getItem('token')) {
     fetchRefer()
    }
    else {
      history('/')
    }
  }, [])





  return (
    <>
      <h5 style={{ borderBottom: "solid" }} className="header"><i class="uil uil-bars"> Distributer Referral Details</i> </h5>

      <div className='container' style={{height : "83vh"}}>
        <table className="table table-bordered table-striped my-3">
          <thead>
            <tr>
              <th scope="col" className='text-center'>Ticket Number</th>
              <th scope="col" className='text-center'>Referred To</th>
              <th scope="col" className='text-center'>Invoice Date</th>
              <th scope="col" className='text-center'>Total Price</th>
              <th scope="col" className='text-center'>Discounted Amount</th>

            </tr>
          </thead>
          <tbody>
            {
              myReferInv.map((element, index) => {
                return <tr key={index}>
                  <th scope="row" className='text-center'>{element.TicketNo}</th>
                  <td className='text-center'>{element.Name}</td>
                  <td className='text-center'>{element.InDate}</td>
                  <td className='text-center'>{element.TotalPrice}</td>
                  <td className='text-center'>{0.1 * element.TotalPrice}</td>
                </tr>
              })}
          </tbody>
        </table>
        <div className="d-flex justify-content-end">
                <Link to="/Admin/Distributer/Details"><button className="btn btn-primary my-2">&larr; Back</button></Link>
            </div>
      </div>
    </>
  )
}

export default DRefTransactions