import React,{useContext,useState,useEffect} from 'react'

function DisPayments() {
  const [details, setDetails] = useState([])
  const hostname = process.env.REACT_APP_SERVER;

  //                           FETCHING DISTRIBUTER DETAILS
const DistDetails = async () => {
const url = `${hostname}/api/Distributer/fetchDetails`
const response = await fetch(url, {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    "auth-token": localStorage.getItem('token')
  }
})
const data = await response.json()
setDetails(data);
}

useEffect(() => {
  DistDetails()
}, [])

  return (
    <div className='container my-3'>
    <h5 style={{ borderBottom : "solid" }} className="my-2"><i className="uil uil-bars">DASHBOARD - Distributer Payment Details</i></h5>
 <div className='dislist'>
 <table className="table table-striped my-3">
   <thead>
     <tr>
       <th scope="col" className='text-center'>S.No</th>
       <th scope="col" className='text-center'>Payment Date</th>
       <th scope="col" className='text-center'>Amount</th>
       <th scope="col" className='text-center'>Reference ID</th>
  </tr>
   </thead>
  <tbody>
   {
     details.map((e)=>{
      return e.Payments.map((element,index)=>{
        return <tr key = {index} >
        <th scope="row" className='text-center'>{index+1}</th>
        <td className='text-center'>{element.Date}</td>
        <td className='text-center'>{element.Amount}</td>
        <td className='text-center'>{element.ReferenceID}</td>
        </tr>
         })
        })
          }
          
   </tbody>
 </table>
 </div>
</div>
  )
}

export default DisPayments