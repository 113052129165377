import React,{useState} from 'react'
import InContext from './InContext'

function Contxt(props) { 
    const [result , setResult] = useState({  });
    const [invoice , setInvoice] = useState({  });
    const [pndng , setPndng] = useState([]);
    const [cls , setCls] = useState([]);
    const [prnt , setPrnt] = useState({  });
    const [products , setProducts] = useState([]);

    return (
    <InContext.Provider value={{ result , setResult , setInvoice, invoice ,pndng, setPndng,cls,setCls ,prnt, setPrnt,products,setProducts}}>
        {props.children}
        </InContext.Provider>
  )
}

export default Contxt