import React,{useEffect} from 'react'
import './CSS/Sidebar.css'
import { Link, useLocation,useNavigate } from 'react-router-dom';

function Sidebar() {
    const history = useNavigate();
    const Logout = () => {
        localStorage.removeItem('token')
        history('/')
      }
    
    let location = useLocation();
    useEffect(() => {
    }, [location])

    return (
        <nav id='sidebar'>
            <div className="col-auto col-sm-3 col-sm-2 px-sm-2 px-0">
                <img src="" alt='Logo' />
                <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-black min-vh-100">

                    <ul className="nav nav-pills flex-column mb-sm-auto mb-0 my-4 align-items-start align-items-sm-start"  >
                        <li>
                            <Link to="/Admin" className={`nav-link ${location.pathname==="/Admin"||location.pathname==="/Admin/AddTicket"||location.pathname==="/Admin/OpenTickets"||location.pathname==="/Admin/CloseTickets"||location.pathname==="/Admin/PendingTickets"||location.pathname==="/Admin/ClosedTickets" ||location.pathname==="/Admin/ClosedTickets/Invoice"?"active":""  } align-middle px-2 my-2`}  >
                                <i className="uil uil-house-user"></i> <span className="ms-1 d-none d-sm-inline">Dashboard</span> </Link>
                        </li>
                        <li>
                            <Link to="/Admin/Employee" className={`nav-link ${location.pathname==="/Admin/Employee"|| location.pathname==="/Admin/Employee/Update"|| location.pathname==="/Admin/Employee/AddNew"|| location.pathname==="/Admin/Employee/Inventory"  ?"active":""} align-middle px-2 my-2`} >
                                <i className="uil uil-users-alt"></i> <span className="ms-1 d-none d-sm-inline">Employee</span></Link>
                        </li>
                        <li>
                            <Link to="/Admin/Inventory" className={`nav-link ${location.pathname==="/Admin/Inventory"?"active":""} align-middle px-2 my-2`} >
                                <i className="uil uil-box"></i> <span className="ms-1 d-none d-sm-inline">Inventory</span></Link>
                        </li>
                        <li>
                            <Link to="/Admin/Invoice" className={`nav-link ${location.pathname==="/Admin/Invoice"|| location.pathname==="/Admin/Invoice/Print" ?"active":""} align-middle px-2 my-2`} >
                                <i className="uil uil-invoice"></i><span className="ms-1 d-none d-sm-inline">Invoice</span> </Link>
                        </li>

                        <li>
                            <Link to="/Admin/Distributer" className={`nav-link ${location.pathname==="/Admin/Distributer" || location.pathname==="/Admin/Distributer/Details"  || location.pathname==="/Admin/Distributer/AddNew"  || location.pathname==="/Admin/Distributer/Details/Payments"   || location.pathname==="/Admin/Distributer/Details/RefPayments" ? "active":"" } align-middle px-2 my-2`} >
                                <i className="uil uil-share-alt"></i><span className="ms-1 d-none d-sm-inline">Distributer</span> </Link>
                        </li>

                    </ul>
                    <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-center"  >
                        <li className="nav-item">
                            <Link to="/"  >
                            <button id='Logout' className='btn btn-primary' onClick={Logout}> <i className="uil uil-signout"> Logout </i> </button> </Link></li>
                    </ul>
                </div>
            </div>
            
        </nav>
       

    )
}

export default Sidebar