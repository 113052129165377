import React, { useContext, useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import InContext from './InContext'
import { useNavigate } from 'react-router-dom'

function Invoice() {
  const componentRef = useRef();
  const history = useNavigate();
  const context = useContext(InContext);
  const { invoice, cls, setCls, products } = context;
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  const [INVOICE, setINVOICE] = useState([])
  const [disable, setDisable] = useState(true)
  const [paymenttyp, setPaymenttyp] = useState("")
  const [refID, setRefID] = useState("NA")
  const [otp, setOtp] = useState()
  const [referInv, setReferInv] = useState([])
  const [genOtp, setGenOtp] = useState()
  const [details, setDetails] = useState({ Payments: [] })
  let TotalDisAmount = 0;
  let pendingAmount = 0;
  let paidAmount = 0;
  const refClose = useRef(null);
  const hostname = process.env.REACT_APP_SERVER
  let disTotal = 0
  let GrandTotal = 0;
  const ID = invoice.TicketID;
  const id = invoice.empAssign;
  let InvoiceNumber = "DKIN" + `-00${INVOICE.length + 1}`;

  //               FETCHING INVOICE
  const InvoiceDetails = async () => {
    const url = `${hostname}/api/Admin/fetchInvoice`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const Invoice = await response.json()
    setINVOICE(Invoice)
  }
  useEffect(() => { InvoiceDetails() }, [])

  invoice.Product.map((element) => {
    products.map((e) => {
      if (e.ProductName === element.Name) {
        const b = element.Quantity * e.Price
        return GrandTotal += b;
      }
    })
  })


  //                    FETCHING REFERALL INVOICES

  const fetchRefer = async () => {
    const refer = invoice.Refer;
    const url = `${hostname}/api/Distributer/fetchRefer?Refer=${refer}`
    const response = await fetch(url)
    const Dist = await response.json()
    setReferInv(Dist)

  }
  useEffect(() => { fetchRefer() }, [])

  referInv.map((element) => {
    let disamt = element.TotalPrice * 0.1
    return TotalDisAmount = TotalDisAmount + disamt;
  })

  if (invoice.Refer !== "Nil") {
    disTotal = GrandTotal - (0.1 * GrandTotal)
  }
  else {
    disTotal = GrandTotal
  }

  if (details.Payments && details.Payments.length !== 0) {
    details.Payments.map((element) => {
      paidAmount = paidAmount + parseInt(element.Amount);
      return pendingAmount = TotalDisAmount - paidAmount
    })
  }

  const sendOTP = async () => {
    var OTP = ""
    var digits = '0123456789';
    for (let i = 0; i < 4; i++) { OTP += digits[Math.floor(Math.random() * 10)] }
    setGenOtp(OTP)
    const url = `${hostname}/api/Employee/getOtp`
    await fetch(url, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ "number": invoice.PhoneNo, "OTP": OTP })
    });
    alert(" OTP Sent Successfully")
  }

  const OTPVerify = async () => {
    if (genOtp === otp) {
      alert(" OTP Verified Successfully")
      refClose.current.click();
      setDisable(false);
    }
    else {
      alert("Invalid OTP")
    }

  }

  const sendSMSToCutsomer = async () => {
    const url = `${hostname}/api/Admin/closeTicketsms`
    await fetch(url, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ "number": invoice.PhoneNo, "TicketNo": invoice.TicketNo, "invoice": InvoiceNumber, "amount": disTotal })
    });
  }

  const sendSMSToDealer = async () => {
    if (details.PhoneNo != undefined) {
      const number = details.PhoneNo;
      const amount = disTotal;
      const disPay = GrandTotal-disTotal;
      const disNewBalance = disPay + pendingAmount;
      const url = `${hostname}/api/Admin/Distributer/creditSmsToDealer`
     const response =  await fetch(url, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ "number": number , "TicketNo": invoice.TicketNo, "amount": amount, "disPay": disPay, "disNewBalance": disNewBalance })
      });
      const data = await response.json();
      console.log(data);

    }
  }

  const addInvoice = async () => {
    if (paymenttyp === "") {
      alert("Please Select Payment Mode")
    }
    else {
      const url = `${hostname}/api/Employee/addInvoice`
      await fetch(url,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            TicketNo: invoice.TicketNo, Name: invoice.Name, ComplainType: invoice.ComplainType, Email: invoice.Email, PhoneNo: invoice.PhoneNo, Address: invoice.Address, Landmark: invoice.Landmark,
            City: invoice.City, State: invoice.State, Zip: invoice.Zip, Country: invoice.Country, Products: invoice.Product, DiscountedPrice: disTotal, TotalPrice: GrandTotal,
            InDate: date, Refer: invoice.Refer, InvoiceNo: InvoiceNumber, Payments: { Status: "Done", PaymentMode: paymenttyp, ReferenceID: refID }, empAssign: invoice.empAssign
          })
        });
      await closeTicket();
      await changeEmpProduct();
      await sendSMSToCutsomer();
      await sendSMSToDealer();

    }

  }

  const closeTicket = async () => {
    const url = `${hostname}/api/Employee/transfer/${ID}`
    const data = await fetch(url,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Status: "Closed", ctDate: date })
      })
    const json = await data.json()
    alert("Ticket Successfully Closed")
    setCls(cls.concat(json));
    history("/Employee/Closed")
  }

  const changeEmpProduct = async () => {
    const url = `${hostname}/api/Employee/updateEmpProduct/${id}`
    await fetch(url,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Products: invoice.Product })
      })
  }

  const fetchDistributerByRefer = async () => {
    const refer = invoice.Refer;
    if (refer !== "Nil") {

      const url = `${hostname}/api/Admin/Distributer/fetchByRefer?refer=${refer}`
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
      const distributerDetails = await response.json();
      setDetails(distributerDetails);
    }
    else {
      null
    }
  }

  useEffect(() => { fetchDistributerByRefer() }, [])


  const onChange = (e) => { setOtp(e.target.value) }
  const onChang = (e) => { setPaymenttyp(e.target.value) }
  const onChng = (e) => { setRefID(e.target.value) }


  return (
    <>
      {/*  MODAL FOR OTP VERIFICATION */}

      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">OTP VERIFICATION</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="form-outline mb-4">
                <label className="form-label"><strong>Mobile Number</strong></label>
                <input type="text" value={invoice.PhoneNo} className="form-control" disabled />
              </div>
              <div className="form-outline mb-4">
                <label className="form-label"><strong>OTP</strong></label>
                <input type="text" onChange={onChange} className="form-control" />
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" ref={refClose} className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary btn-sm" onClick={OTPVerify} >Verify OTP</button>
              <button type="button" className="btn btn-primary btn-sm" onClick={sendOTP} >Resend OTP</button>
            </div>
          </div>
        </div>
      </div>


      <div className='container invoice my-3' ref={componentRef} style={{ border: "solid" }}>
        <header>
          <h1 className='text-center fw-bold'>INVOICE</h1>
        </header>

        <div className="row">
          <div className="col CD">

            <div className='row'>
              <div className="col">
                <label className="form-label mx-2 fw-bold">Customer's Name </label>
                <label className="form-label mx-1">{invoice.Name}</label>
              </div></div>

              <div className="row">
              <div className="col">
                <label className="form-label mx-2 "><strong>Date </strong></label>
                <label className="form-label mx-1 ">{date}</label>
              </div></div>

            <div className='row'>
              <div className="col">
                <label className="form-label mx-2 fw-bold">Mobile No.</label>
                <label className="form-label mx-1 "> {invoice.PhoneNo}</label>
              </div></div>

            <div className="row">
              <div className="col">
                <label className="form-label mx-2 fw-bold">Email</label>
                <label className="form-label mx-1 "> {invoice.Email}</label>
              </div></div>

            <div className='row'>
              <div className="col">
                <label className="form-label mx-2 fw-bold">Address</label>
                <label className="form-label mx-1 "> {invoice.Address}</label>
              </div>
              <label className="form-label mx-2 "> {invoice.City} {invoice.State} ,{invoice.Country}({invoice.Zip})</label>
            </div>

          </div>

          <div className="col ComD">
          <div className="row">
              <div className="col">
                <label className="form-label fw-bold">Company Name : </label>
                <label className="form-label mx-2  "> Shopsneaky</label>
              </div>

              <div className="row">
                <div className="col">
                  <label className="form-label fw-bold">GSTIN : </label>
                  <label className="form-label mx-2  ">09ADIPG2245C1ZY</label>
                </div></div>

              <div className="row">
                <div className="col">
                  <label className="form-label fw-bold">Invoice Number </label>
                  <label className="form-label mx-1  ">{InvoiceNumber}</label>
                </div></div>
            </div>
           
            <div className="row">
              <div className="col">
                <label className="form-label fw-bold">Contact Number </label>
                <label className="form-label mx-1 ">7017618082</label>
              </div></div>
            <div className="row">
              <div className="col">
                <label className="form-label fw-bold">Complaint Description</label>
                <label className="form-label mx-1 ">{invoice.ComplainType}</label>
              </div></div>
          </div>
        </div>

        <div className='container my-5'>


          <table className='itable'>
            <thead>
              <tr>
                <th scope="col" className='text-center'>S.No</th>
                <th scope="col" className='text-center'>Items Used</th>
                <th scope="col" className='text-center'>Quantity</th>
                <th scope="col" className='text-center'>Price / Item</th>
                <th scope="col" className='text-center'>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {
                invoice.Product.map((element, index) => {
                  return <tr key={index}>
                    <th scope="row" className='text-center'>{index + 1}</th>
                    <td className='text-center'>{element.Name}</td>
                    <td className='text-center'>{element.Quantity}</td>
                    {
                      products.map((e) => {
                        if (e.ProductName === element.Name && Object.keys(element).length === 2) {
                          return <> <td className='text-center'>Rs. {e.Price}</td>
                            <td className='text-center'>Rs.{element.Quantity * e.Price}</td> </>
                        }
                      })}
                  </tr>
                })}
            </tbody>
          </table>

          <table className='table table-total'>
            <tr><td className='text-end'><strong>Total Price : </strong>Rs.{GrandTotal}</td></tr>
            <tr><td className='text-end'><strong>Total Discount :</strong>Rs.{GrandTotal - disTotal}</td></tr>
            <tr> <td className='text-end'><strong>Grand Total :   Rs.{disTotal}</strong></td></tr>
          </table>


        </div>
      </div>
      <table className='table table-paytyp' style={{ boxShadow: "none" }}>
        <tr>
          <td className='text-center'><strong> Payment Method :</strong> <select type="text" className="paytyp-input" onChange={onChang} >
            <option  >Cash/Online </option>
            <option value="Cash" >Cash</option>
            <option value="Online">Online</option>
          </select></td>
          <td className='text-center'><strong>Transaction Number :</strong>{paymenttyp === "Cash" ? <input type="text" className='paytyp-input' disabled /> : <input type="text" className='paytyp-input' onChange={onChng} />}</td>
        </tr>
      </table>

      <div className='container container-paytyp'>
        <label className='input fw-bold mx-2 text-center'> Payment Method :</label>
        <select type="text" className="text-center paytyp-input" onChange={onChang} >
          <option  >Cash/Online </option>
          <option value="Cash" >Cash</option>
          <option value="Online">Online</option>
        </select>
        <label className='text-center mx-2 mt-2'><strong>Transaction Number :</strong></label>
        {paymenttyp === "Cash" ? <input type="text" className='paytyp-input' disabled /> : <input type="text" className='paytyp-input' onChange={onChng} />}
      </div>


      <div className="d-flex justify-content-center ">
        <button className='btn btn-primary btn-sm mx-2 my-2' onClick={addInvoice} style={{ borderRadius: "16px" }} disabled={disable} >Close Ticket</button>
        <button type="button" className="btn btn-primary btn-sm mx-2 my-2" onClick={sendOTP} data-bs-toggle="modal" style={{ borderRadius: "16px" }} data-bs-target="#exampleModal">OTP Verification</button>
        <Link to="/Employee/GenInvoice"> <button className="btn btn-primary btn-sm mx-2 my-2" style={{ borderRadius: "16px" }} type="button">Cancel</button></Link>
      </div>

    </>
  )
}

export default Invoice





