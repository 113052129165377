const initialState = []
const Invoice = (state = initialState, action) => {
    const Invoice = action.payload;
    if (action.type === "FETCH_INVOICE_DETAILS") {
        return [...state, ...Invoice]
    }  
        else {
    return state;
}
}
export default Invoice

