import { createStore ,applyMiddleware} from "redux";
import { persistStore,persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk";
import rootReducer from "./Reducers";
const persistConfig ={
    key:'persist-stores',
    storage,
    whitelist : ['Edit',"PInvoice"]
}
const persistedReducer=persistReducer(persistConfig,rootReducer)
const store =  createStore(persistedReducer,applyMiddleware(thunk),
window.__REACT_DEVTOOLS_EXTENSION__&& window.__REACT.__REACT_DEVTOOLS_EXTENSION__())
export const persistor = persistStore(store)
export default store