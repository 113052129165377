import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function TcktInvoice() {
  const PInvoice = useSelector((state) => state.PInvoice)
  const [Product, setProduct] = useState([])
  const hostname = process.env.REACT_APP_SERVER

  //            FETCHING INVENTORY PRODUCTS
  const ProductDetails = async () => {
    const url = `${hostname}/api/Inventory/fetchProduct`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const prdctDtls = await response.json()
    console.log(prdctDtls);
    setProduct(prdctDtls)
  }

  useEffect(() => { ProductDetails() }, [])

  return (
    <>
      <div className='container' style={{ border: "solid" }}>
        <header>
          <h1 className='text-center fw-bold'>INVOICE</h1>
        </header>

        <div className="row">
          <div className="col">

            <div className='row'>
              <div className="flex flex-col">
                <label className="form-label mx-2 fw-bold">Customer's Name :</label>
                <label className="form-label ">{PInvoice.Name}</label>
              </div></div>

            <div className="row">
              <div className="col">
                <label className="form-label mx-2"><strong>Date :</strong></label>
                <label className="form-label mx-2 ">{PInvoice.InDate}</label>
              </div></div>

            <div className='row'>
              <div className="flex flex-col">
                <label className="form-label mx-2 fw-bold">Mobile No. :</label>
                <label className="form-label mx-2 ">{PInvoice.PhoneNo}</label>
              </div></div>

            <div className="row">
              <div className="flex flex-col">
                <label className="form-label mx-2 fw-bold">Email :</label>
                <label className="form-label  ">{PInvoice.Email}</label>
              </div></div>

            <div className='row'>
              <div className="flex flex-col">
                <label className="form-label mx-2 fw-bold">Address :</label>
                <label className="form-label ">{PInvoice.Address}</label>
              </div>
              <label className="form-label mx-2 ">{PInvoice.City}({PInvoice.Zip}) {PInvoice.State} ,{PInvoice.Country}</label>
            </div>



          </div>

          <div className="col-3">

            <div className="row">
              <div className="col">
                <label className="form-label fw-bold">Company Name : </label>
                <label className="form-label mx-2  "> Shopsneaky</label>
              </div>

              <div className="row">
                <div className="col">
                  <label className="form-label fw-bold">GSTIN : </label>
                  <label className="form-label mx-2  ">09ADIPG2245C1ZY</label>
                </div></div>

              <div className="row">
                <div className="col">
                  <label className="form-label fw-bold">Invoice No. :</label>
                  <label className="form-label mx-2  ">{PInvoice.InvoiceNo}</label>
                </div></div>
            </div>

            <div className="row">
              <div className="col">
                <label className="form-label fw-bold">Contact No.</label>
                <label className="form-label mx-2 ">7017618082</label>
              </div></div>

            <div className="row">
              <div className="flex flex-col">
                <label className="form-label fw-bold">Complaint Description :</label>
                <label className="form-label mx-2 ">{PInvoice.ComplainType}</label>
              </div></div>

            <div className="row">
              <div className="col">
                <label className="form-label fw-bold">Complaint handled by:</label>
                <label className="form-label">{PInvoice.Employee}</label>
              </div></div>

          </div>

        </div>

        <div className='container my-5'>
          <table className='table table-paytyp' style={{ boxShadow: "none" }}>
            <tr>
              <td className='text-center'><strong>Payment Method :</strong>{PInvoice.Payments.PaymentMode}</td>
              <td className='text-center'><strong>Transaction Number :</strong>{PInvoice.Payments.ReferenceID}</td>
              <td className='text-center'><strong>Refer :</strong>{PInvoice.Refer}</td>
            </tr>
          </table>
          <table className='table'>
            <thead>
              <tr>
                <th scope="col" className='text-center'>S.No</th>
                <th scope="col" className='text-center'>Items Used</th>
                <th scope="col" className='text-center'>Quantity</th>
                <th scope="col" className='text-center'>Price / Item</th>
                <th scope="col" className='text-center'>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {
                PInvoice.Products.map((element, index) => {
                  return <tr key={index}>
                    <th scope="row" className='text-center'>{index + 1}</th>
                    <td className='text-center'>{element.Name}</td>
                    <td className='text-center'>{element.Quantity}</td>
                    {
                      Product.map((e) => {
                        if (e.ProductName === element.Name) {
                          return <> <td className='text-center'>Rs.{e.Price}</td>
                            <td className='text-center'>Rs.{element.Quantity * e.Price}</td> </>
                        }
                      })}
                  </tr>
                })}
            </tbody>
          </table>

          <table className='table table-total'>
            <tr><td className='text-end'><strong>Total Price : </strong>Rs.{PInvoice.TotalPrice}</td></tr>
            <tr><td className='text-end'><strong>Total Discount :</strong>Rs.{PInvoice.TotalPrice - PInvoice.DiscountedPrice}</td></tr>
            <tr> <td className='text-end'><strong>Grand Total :   Rs.{PInvoice.DiscountedPrice}</strong></td></tr>
          </table>


        </div>
        <div className="d-flex justify-content-center ">
          {/* <button className='btn btn-primary btn-sm mx-2 my-2' onClick={Print} style={{ borderRadius: "16px" }} >Print</button> */}
          <Link to="/Admin/ClosedTickets"><button className="btn btn-primary btn-sm my-2">Cancel</button></Link>
        </div>
      </div>
    </>
  )
}

export default TcktInvoice