const initialState = []
const prdctReducer = (state = initialState, action) => {

    const prdctDtls = action.payload;
    if (action.type === "FETCH_PRDCT_DETAILS") {
        return [...state, ...prdctDtls]
    }
    else
        if (action.type === "UPDATE_PRDCT_DETAILS") {
            const i = state.findIndex((element) => element._id === prdctDtls._id)
            // console.log(prdctDtls)
            state[i] = prdctDtls;
            const newState = state;
            // console.log(newState)
            return newState;

        }
        else
            if (action.type === "DELETE_PRDCT") {
                const result = state.filter((state) => { return state._id !== prdctDtls._id })
                return [...result]
            }
            else {
                return state;
            }
}
export default prdctReducer