import React,{useEffect,useState} from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import "./CSS/Tickets.css"

function AdminCard() {
 
  const [OpenLength, setOpenLength] = useState(0)
  const [CloseLength, setCloseLength] = useState(0)
  const [PendingLength, setPendingLength] = useState(0)
  const [TotalLength, setTotalLength] = useState(0)
  const hostname = process.env.REACT_APP_SERVER


   //                 FETCHING TOTAL TICKETS
   const fetchTT = async () => {
    const url = `${hostname}/api/Admin/fetchTotal`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const Total = await response.json()
    setTotalLength(Total.length)
    // console.log(Total);
    // dispatch(actionCreater.TotalTckt(Total));
  }

  //                 FETCHING OPEN TICKETS
  const fetchOT = async () => {
    const url = `${hostname}/api/Admin/fetchOT`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const Open = await response.json()
    setOpenLength(Open.length)
    // console.log(Open);
    // dispatch(actionCreater.OpenTckt(Open));
  }

  //                 FETCHING CLOSED TICKETS
  const fetchCls = async () => {
    const url = `${hostname}/api/Admin/fetchCls`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const Close = await response.json()
    setCloseLength(Close.length)
    // console.log(Close);
    // dispatch(actionCreater.CloseTckt(Close));
  }

  //                FETCHING PENDING TICKETS
  const fetchPending = async () => {
    const url = `${hostname}/api/Admin/fetchPndg`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const Pending = await response.json()
    setPendingLength(Pending.length)
    // console.log(Pending);
    // dispatch(actionCreater.PendingTckt(Pending));
  }

  let location = useLocation();
    useEffect(() => {
    }, [location])

    useEffect(() => { fetchOT() },[])
  
    useEffect(() => { fetchTT() },[])
  
    useEffect(() => { fetchCls() },[])
  
    useEffect(() => { fetchPending() },[])

  return (
    <>
      <h5 style={{ borderBottom: "solid" }} className="header"><i className="uil uil-bars">DASHBOARD - Tickets</i> </h5>
      <div className="d-flex justify-content-end">
        <Link to="/Admin/AddTicket"> <button className="btn btn-dark btn-sm" id="Ad" type="button">Add Ticket <i class="uil uil-ticket"></i></button></Link>
       
      </div>


      <div className='row justify-content-center my-2'>

        <div className='crd4 col-2'>
          <Link to="/Admin">
            <div className= {`card mb-3 ${location.pathname==="/Admin"?"active":""}`} style={{ maxWidth: "10rem", maxHeight: "10rem" }}>
              <div className="card text-center" >Total Tickets</div>
              <div className= "card-body" >
                <h5 className="card-title text-center ">{TotalLength}</h5>
              </div></div></Link>
        </div>


        <div className='crd1 col-2'>
          <Link to="/Admin/OpenTickets">
            <div className= {`card mb-3 ${location.pathname==="/Admin/OpenTickets"?"active":""}`} style={{ maxWidth: "10rem", maxHeight: "10rem" }}>
             <div className="card text-center">Open Ticket</div>
              <div className="card-body">
                <h5 className="card-title text-center">{OpenLength}</h5>
              </div>
            </div></Link>
        </div>

        <div className='crd2 col-2'>
          <Link to="/Admin/PendingTickets">
            <div className= {`card  mb-3 ${location.pathname==="/Admin/PendingTickets"?"active":""}`} style={{ maxWidth: "10rem", maxHeight: "10rem" }}>

              <div className="card text-center">Pending Tickets</div>
              <div className="card-body">
                <h5 className="card-title text-center">{PendingLength}</h5>
              </div>
            </div></Link>
        </div>

        <div className='crd3 col-2'>
          <Link to="/Admin/ClosedTickets">
            <div className= {`card mb-3 ${location.pathname==="/Admin/ClosedTickets"?"active":""}`} style={{ maxWidth: "10rem", maxHeight: "10rem" }}>
              <div className="card text-center">Closed Tickets</div>
              <div className="card-body">
                <h5 className="card-title text-center">{CloseLength}</h5>
              </div>
            </div> </Link>
        </div>
      </div>
      <Outlet />

    </>
  )
}

export default AdminCard