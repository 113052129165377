const initialState = {}
const Edit = ( state =initialState , action)=>{
    const Edit = action.payload;
    if (action.type === "EDIT")
    {
        // console.log(Edit)
        return {...Edit }
    }
    else {
    return state;
    }
}
export default Edit

