import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function Payments() {
  const Edit = useSelector((state) => state.Edit)
  return (
    <div className='container my-3'>
    <h5 style={{ borderBottom : "solid" }} className="my-2"><i className="uil uil-bars">DASHBOARD - Distributer Payment Details</i></h5>
 <div className='dislist'>
 <table className="table table-bordered table-striped my-3">
   <thead>
     <tr>
       <th scope="col" className='text-center'>S.No</th>
       <th scope="col" className='text-center'>Payment Date</th>
       <th scope="col" className='text-center'>Amount</th>
       <th scope="col" className='text-center'>Reference ID</th>
  </tr>
   </thead>
  
   <tbody>
   {
        Edit.Payments.map((element,index)=>{
        return <tr key = {index} >
        <th scope="row" className='text-center'>{index+1}</th>
        <td className='text-center'>{element.Date}</td>
        <td className='text-center'>{element.Amount}</td>
        <td className='text-center'>{element.ReferenceID}</td>
        </tr>
         })
          }
   </tbody>
 </table>
 </div>
 <div className="d-flex justify-content-end">
                <Link to="/Admin/Distributer/Details"><button className="btn btn-primary my-2">&larr; Back</button></Link>
            </div>
</div>
  )
}

export default Payments