import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { actionCreater } from '../../../State/index'
import { useDispatch } from "react-redux";

function AddDistributer() {
    const [details, setDetails] = useState({})
    const history = useNavigate();
    const dispatch = useDispatch();
    const hostname = process.env.REACT_APP_SERVER
    // console.log(details);


    const submitDetails = async (event) => {
        if (details.Account === details.CAccount) {
            await event.preventDefault()
            const url = `${hostname}/api/Admin/Distributer/addDistributer`
            const data = await fetch(url,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        username: details.username, PhoneNo: details.PhoneNo, Email: details.Email, Refer: details.Refer,
                        Address: details.Address, City: details.City, State: details.State, Zip: details.Zip, CAccount: details.CAccount, IFSC: details.IFSC, Payments: [], AccountName : details.AccountName
                    })
                });

            alert("Distributer Added Successfully")
            const result = [await data.json()];
            dispatch(actionCreater.Distributer(result))
            history("/Admin/Distributer")
            // console.log(result);
        }
        else {
            alert("Account Number Should Match")

        }
    }


    const onChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value })
    }
    return (
        <div className='container'>
            <h5 style={{ borderBottom: "solid" }} className="my-2"><i className="uil uil-bars">DASHBOARD - Add Distributer</i></h5>
              <form className="row" onSubmit={submitDetails} >
                <div className="d-flex justify-content-center ">
                    <button className="btn btn-dark btn-sm" style={{ borderRadius: "16px" }} id="DisBtn" disabled> Add New Distributer</button>
                </div>
                <div className="d-flex justify-content-start ">
                    <button className="btn  btn-sm my-2" style={{ borderRadius: "16px" }} id="DisBtn" disabled>Personal Details</button>
                </div>
                <div className="col-md-6">
                    <label className="form-label"><strong>Name*</strong></label>
                    <input type="text" onChange={onChange} name="username" className="form-control" required={true} minLength={3} />
                </div>
                <div className="col-md-6">
                    <label className="form-label"><strong>Contact Number*</strong></label>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                         { details.PhoneNo === undefined ? <span className="input-group-text" id="basic-addon1"><i className="uil uil-mobile-android-alt"></i></span> : details.PhoneNo.toString().length === 0 ? <span className="input-group-text" id="basic-addon1"><i className="uil uil-mobile-android-alt"></i></span> : details.PhoneNo.toString().length === 10 ? <span className="input-group-text" id="basic-addon1"><i className="uil uil-check"></i></span> :<span className="input-group-text" id="basic-addon1"><i className="uil uil-times"></i></span>}
                        </div>
                        <input type="number" onChange={onChange} name="PhoneNo" className="form-control" required={true} minLength={10} maxLength={10}/>
                    </div>
                </div>
                <div className="col-md-6 my-3">
                    <label className="form-label"><strong>Email*</strong></label>
                    <input type="email" onChange={onChange} name="Email" className="form-control" required={true} minLength={3} />
                </div>

                <div className="col-md-6 my-3">
                    <label className="form-label"><strong>Address*</strong></label>
                    <input type="text" onChange={onChange} name="Address" className="form-control" required={true} minLength={5} />
                </div>

                <div className="col-md-3">
                    <label className="form-label"><strong>City*</strong></label>
                    {

                        details.State === "Andhra Pradesh" ?
                            <select name="City" id="state" onChange={onChange} className="form-control">
                                <option >Select City</option>
                                <option value="Visakhapatnam">Visakhapatnam</option>
                                <option value="Vijayawada">Vijayawada</option>
                                <option value="Guntur">Guntur</option>
                            </select> :

                            details.State === "Arunanchal Pradesh" ?
                                <select name="City" id="state" onChange={onChange} className="form-control">
                                    <option >Select City</option>
                                    {/* <option value="Visakhapatnam">Visakhapatnam</option> 
                    <option value="Vijayawada">Vijayawada</option> 
                    <option value="Guntur">Guntur</option>  */}
                                </select> :

                                details.State === "Assam" ?
                                    <select name="City" id="state" onChange={onChange} className="form-control">
                                        <option >Select City</option>
                                        <option value="Guwahati">Guwahati</option>
                                        <option value="	Dibrugarh">	Dibrugarh</option>
                                        <option value="Silchar">Silchar</option>
                                    </select> :

                                    details.State === "Bihar" ?
                                        <select name="City" id="state" onChange={onChange} className="form-control">
                                            <option >Select City</option>
                                            <option value="Patna">	Patna</option>
                                            <option value="Gaya">Gaya</option>
                                            <option value="Bhagalpur">Bhagalpur</option>
                                            <option value="Muzaffarpur">Muzaffarpur</option>
                                        </select> :

                                        details.State === "Chhattisgarh" ?
                                            <select name="City" id="state" onChange={onChange} className="form-control">
                                                <option >Select City</option>
                                                <option value="Raipur">Raipur</option>
                                                <option value="Bhilai">Bhilai</option>
                                                <option value="Jagdalpur">Jagdalpur</option>
                                                <option value="Bilaspur">Bilaspur</option>
                                            </select> :
                                            details.State === "Goa" ?
                                                <select name="City" id="state" onChange={onChange} className="form-control">
                                                    <option >Select City</option>
                                                    <option value="Raipur">Raipur</option>
                                                    <option value="Bhilai">Bhilai</option>
                                                    <option value="Jagdalpur">Jagdalpur</option>
                                                    <option value="Bilaspur">Bilaspur</option>
                                                </select> :
                                                details.State === "Gujarat" ?
                                                    <select name="City" id="state" onChange={onChange} className="form-control">
                                                        <option >Select City</option>
                                                        <option value="Ahmedabad">	Ahmedabad</option>
                                                        <option value="Surat">Surat</option>
                                                        <option value="Vadodara">Vadodara</option>
                                                        <option value="Bhavnagar">Bhavnagar</option>
                                                    </select> :
                                                    details.State === "Haryana" ?
                                                        <select name="City" id="state" onChange={onChange} className="form-control">
                                                            <option >Select City</option>
                                                            <option value="Kurukshetra">Kurukshetra</option>
                                                            <option value="Gurgaon">Gurgaon</option>
                                                            <option value="Faridabad">Faridabad</option>
                                                            <option value="Panipat">Panipat</option>
                                                            <option value="Panchkula">Panchkula</option>
                                                        </select> :
                                                        details.State === "Himachal Pradesh" ?
                                                            <select name="City" id="state" onChange={onChange} className="form-control">
                                                                <option >Select City</option>
                                                                <option value="Shimla">Shimla</option>
                                                                <option value="Dharamsala">Dharamsala</option>
                                                                <option value="Solan">Solan</option>
                                                                <option value="Mandi">	Mandi</option>
                                                            </select> :
                                                            details.State === "Jammu and Kashmir" ?
                                                                <select name="City" id="state" onChange={onChange} className="form-control">
                                                                    <option >Select City</option>
                                                                    <option value="Shimla">Shimla</option>
                                                                    <option value="Dharamsala">Dharamsala</option>
                                                                    <option value="Solan">Solan</option>
                                                                    <option value="Mandi">	Mandi</option>
                                                                </select> :
                                                                details.State === "Jharkhand" ?
                                                                    <select name="City" id="state" onChange={onChange} className="form-control">
                                                                        <option >Select City</option>
                                                                        <option value="Jamshedpur">Jamshedpur</option>
                                                                        <option value="Dhanbad">Dhanbad</option>
                                                                        <option value="Ranchi">Ranchi</option>
                                                                        <option value="Bokaro Steel City">Bokaro Steel City</option>
                                                                    </select> :
                                                                    details.State === "Karnataka" ?
                                                                        <select name="City" id="state" onChange={onChange} className="form-control">
                                                                            <option >Select City</option>
                                                                            <option value="Bangalore">Bangalore</option>
                                                                            <option value="Hubli-Dharwad">Hubli-Dharwad</option>
                                                                            <option value="Mysore">Mysore</option>
                                                                            <option value="Gulbarga">Gulbarga</option>
                                                                        </select> :
                                                                        details.State === "Kerala" ?
                                                                            <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                <option >Select City</option>
                                                                                <option value="Thiruvananthapuram">Thiruvananthapuram</option>
                                                                                <option value="Kozhikode">Kozhikode</option>
                                                                                <option value="	Kochi">	Kochi</option>
                                                                                <option value="Kollam">Kollam</option>
                                                                            </select> :
                                                                            details.State === "Madhya Pradesh" ?
                                                                                <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                    <option >Select City</option>
                                                                                    <option value="Gwalior">Gwalior</option>
                                                                                    <option value="Bhopal">Bhopal</option>
                                                                                    <option value="Jabalpur">Jabalpur</option>
                                                                                    <option value="Indore">Indore</option>
                                                                                </select> :
                                                                                details.State === "Maharashtra" ?
                                                                                    <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                        <option >Select City</option>
                                                                                        <option value="Mumbai">Mumbai</option>
                                                                                        <option value="Pune">Pune</option>
                                                                                        <option value="Nagpur">Nagpur</option>
                                                                                        <option value="Nashik">Nashik</option>
                                                                                        <option value="Thane">Thane</option>
                                                                                    </select> :

                                                                                    details.State === "Manipur" ?
                                                                                        <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                            <option >Select City</option>
                                                                                            <option value="Imphal">Imphal</option>
                                                                                            <option value="Ukhru">Ukhru</option>
                                                                                            <option value="Bishnupur">Bishnupur</option>
                                                                                            <option value="Thoubal">Thoubal</option>
                                                                                        </select> :

                                                                                        details.State === "Meghalaya" ?
                                                                                            <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                <option >Select City</option>
                                                                                                <option value="Shillong">Shillong</option>
                                                                                                <option value="Cherrapunji">Cherrapunji</option>
                                                                                                <option value="Baghmara">Baghmara</option>
                                                                                                <option value="Dawki">Dawki</option>
                                                                                            </select> :

                                                                                            details.State === "Mizoram" ?
                                                                                                <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                    <option >Select City</option>
                                                                                                    <option value="Aizawl ">Aizawl </option>
                                                                                                    <option value="Lunglei">Lunglei</option>
                                                                                                    <option value="Mamit">Mamit</option>
                                                                                                    <option value="Kolasib">Kolasib</option>
                                                                                                </select> :
                                                                                                details.State === "Nagaland" ?
                                                                                                    <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                        <option >Select City</option>
                                                                                                        <option value="Changtongya">Changtongya</option>
                                                                                                        <option value="Chumukedima">Chumukedima</option>
                                                                                                        <option value="Dimapur">Dimapur</option>
                                                                                                        <option value="Diphupar-">Diphupar-</option>
                                                                                                    </select> :
                                                                                                    details.State === "Odhisa" ?
                                                                                                        <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                            <option >Select City</option>
                                                                                                            <option value="Bhubaneswar">Bhubaneswar</option>
                                                                                                            <option value="Cuttack">Cuttack</option>
                                                                                                            <option value="Raurkela">Raurkela</option>
                                                                                                            <option value="Brahmapur">Brahmapur</option>
                                                                                                            <option value="Puri">Puri</option>
                                                                                                        </select> :
                                                                                                        details.State === "Punjab" ?
                                                                                                            <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                <option >Select City</option>
                                                                                                                <option value="Ludhiana">Ludhiana</option>
                                                                                                                <option value="Amritsar">Amritsar</option>
                                                                                                                <option value="Chandigarh">Chandigarh</option>
                                                                                                                <option value="Jalandhar">Jalandhar</option>
                                                                                                                <option value="Bhatinda">Bhatinda</option>
                                                                                                            </select> :
                                                                                                            details.State === "Rajasthan" ?
                                                                                                                <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                    <option >Select City</option>
                                                                                                                    <option value="Jaipur">Jaipur</option>
                                                                                                                    <option value="Jodhpur">Jodhpur</option>
                                                                                                                    <option value="kota">kota</option>
                                                                                                                    <option value="Bhiwadi">Bhiwadi</option>
                                                                                                                    <option value="Bikaner">Bikaner</option>
                                                                                                                </select> :
                                                                                                                details.State === "Sikkim" ?
                                                                                                                    <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                        <option >Select City</option>
                                                                                                                        <option value="Gangtok">Gangtok</option>
                                                                                                                        <option value="Rabdentse">Rabdentse</option>
                                                                                                                        <option value="Pelling">Pelling</option>
                                                                                                                        {/* <option value="Bhiwadi">Bhiwadi</option>
                                                                                                                <option value="Bikaner">Bikaner</option> */}
                                                                                                                    </select> :
                                                                                                                    details.State === "Rajasthan" ?
                                                                                                                        <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                            <option >Select City</option>
                                                                                                                            <option value="Jaipur">Jaipur</option>
                                                                                                                            <option value="Jodhpur">Jodhpur</option>
                                                                                                                            <option value="kota">kota</option>
                                                                                                                            <option value="Bhiwadi">Bhiwadi</option>
                                                                                                                            <option value="Bikaner">Bikaner</option>
                                                                                                                        </select> :
                                                                                                                        details.State === "Tamil Nadu" ?
                                                                                                                            <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                                <option >Select City</option>
                                                                                                                                <option value="Chennai">Chennai</option>
                                                                                                                                <option value="Coimbatore">Coimbatore</option>
                                                                                                                                <option value="Madurai">Madurai</option>
                                                                                                                                <option value="Tiruchirappalli">Tiruchirappalli</option>
                                                                                                                                <option value="Tiruppur">Tiruppur</option>
                                                                                                                            </select> :
                                                                                                                            details.State === "Telangana" ?
                                                                                                                                <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                                    <option >Select City</option>
                                                                                                                                    <option value="Hyderabad">Hyderabad</option>
                                                                                                                                    <option value="Warangal">Warangal</option>
                                                                                                                                    <option value="Nizamabad">Nizamabad</option>
                                                                                                                                    <option value="Khammam">Khammam</option>
                                                                                                                                </select> :
                                                                                                                                details.State === "Tripura" ?
                                                                                                                                    <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                                        <option >Select City</option>
                                                                                                                                        <option value="Agartala">Agartala</option>
                                                                                                                                        <option value="Udaipur">Udaipur</option>
                                                                                                                                        <option value="Dharmanagar">Dharmanagar</option>
                                                                                                                                        <option value="Jampui Hills">Jampui Hills</option>
                                                                                                                                    </select> :

                                                                                                                                    details.State === "Uttar Pradesh" ?
                                                                                                                                        <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                                            <option >Select City</option>
                                                                                                                                            <option value="Kanpur">Kanpur</option>
                                                                                                                                            <option value="Lucknow">Lucknow</option>
                                                                                                                                            <option value="Ghaziabad">Ghaziabad</option>
                                                                                                                                            <option value="Agra">Agra</option>
                                                                                                                                        </select> :
                                                                                                                                        details.State === "Uttarakhand" ?
                                                                                                                                            <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                                                <option >Select City</option>
                                                                                                                                                <option value="Dehradun">Dehradun</option>
                                                                                                                                                <option value="	Haridwar">	Haridwar</option>
                                                                                                                                            <option value="	Roorkee">	Roorkee</option>
                                                                                                                                            <option value="	Haldwani">	Haldwani</option>
                                                                                                                                            <option value="Kashipur">Kashipur</option>
                                                                                                                                            </select> :
                                                                                                                                        details.State === "West Bengal" ?
                                                                                                                                            <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                                                <option >Select City</option>
                                                                                                                                                <option value="Dehradun">Dehradun</option>
                                                                                                                                                <option value="Haridwar">Haridwar</option>
                                                                                                                                            <option value="Kashipur">Kashipur</option>
                                                                                                                                            <option value="	Haldwani">Haldwani</option>
                                                                                                                                            <option value="	Roorkee">Roorkee</option>
                                                                                                                                            </select> :

                                                                                                                                            details.State === "Delhi" ?
                                                                                                                                                <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                                                    <option >Select City</option>
                                                                                                                                                    <option value="Delhi">Delhi</option>
                                                                                                                                                </select> : 
                                                                                                                                            details.State === "Chandigarh" ?
                                                                                                                                                <select name="City" id="state" onChange={onChange} className="form-control">
                                                                                                                                                    <option >Select City</option>
                                                                                                                                                    <option value="Chandigarh">Chandigarh</option>
                                                                                                                                                </select> : 
                                                                                                                                                <input type="text" value={"Please Select State First"}  className="form-control"  disabled/>
                    }
                </div>    

                <div className="col-md-3">
                    <label className="form-label"><strong>State*</strong></label>
                    <select name="State" id="state" onChange={onChange} className="form-control" required={true} >
                        <option >Select State</option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>      
                        <option value="Delhi">Delhi</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                    </select>
                </div>

                <div className="col-md">
                    <label className="form-label"><strong>Zip*</strong></label>
                    <input type="number" onChange={onChange} name="Zip" className="form-control" required={true} />
                </div>

                <div className="col-md-3">
                    <label className="form-label"><strong>Refer*</strong></label>
                    <input type="text" onChange={onChange} name="Refer" className="form-control" required={true} minLength={1}  />
                </div>


                <div className="d-flex justify-content-start my-2">
                    <button className="btn btn-primary btn-sm my-2" style={{ borderRadius: "16px" }} id="DisBtn" disabled>Bank Details</button>
                </div>
               
                <div className="col-md-12">
                    <label className="form-label"><strong>Account Holder's Name*</strong>{alert}</label>
                    <input type="text" className="form-control" required="true" onChange={onChange} name="AccountName" minLength={2} />
                </div>
               
                <div className="col-md-12">
                    <label className="form-label"><strong>Account Number*</strong></label>
                    <input type="text" onChange={onChange} name="Account" className="form-control" required={true} />
                </div>
                <div className="col-md-12 my-3">
                    <label className="form-label"><strong>Confirm Account Number*</strong></label>
                    <input type="text" onChange={onChange} name="CAccount" className="form-control" required={true} />
                </div>

                <div className="col-md-12">
                    <label className="form-label"><strong>IFSC Code</strong></label>
                    <input type="text" onChange={onChange} name="IFSC" className="form-control" required={true} />
                </div>
                <div className="d-flex justify-content-center ">
                <button className="btn form-btn btn-sm my-2" >Submit</button>
                <Link to="/Admin/Employee"><button className="btn form-btn mx-5 my-2">Cancel</button></Link>
            </div>
            </form>
           
        </div>
    )
}

export default AddDistributer