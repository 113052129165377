const initialState = []
const PendingTckt = ( state =initialState , action)=>{
  
    if (action.type === "FETCH_PT_DETAILS")
    {
        const Pending = action.payload 
        // console.log(Pending)
        return [...state , ...Pending ]
    }
    else
    {
    return state;
    }
}
export default PendingTckt