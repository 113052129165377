import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import EList from './EList'


function EmployeeList() {
  const [Details, setDetails] = useState([])
  const hostname = process.env.REACT_APP_SERVER
    
    //                FETCHING EMPLOYEE
    const fetchEmp = async () => {
      const url = `${hostname}/api/Admin/Employee/fetchEmp`
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem('token')
        }
      })
      const empDetails = await response.json()
     setDetails(empDetails)
    }
  useEffect(() => { fetchEmp() },[])


  
   
  return (
    <>
      <h5 style={{ borderBottom: "solid" }} className="my-2"><i className="uil uil-bars"> DASHBOARD - Employee List</i></h5>
      <div className="d-flex justify-content-end">
        <Link to="AddNew"> <button className="btn btn-dark btn-sm" id="Ad" style={{ borderRadius: "16px" }} type="button">Add Employee</button></Link>
      </div>
    
        <table className="table table-striped table-bordered my-4">
          <thead>
            <tr>
              <th scope="col" className='text-center'>S.No</th>
              <th scope="col" className='text-center' >Full Name</th>
              <th scope="col" className='text-center'>Phone No.</th>
              <th scope="col" className='text-center' >Email</th>
              <th scope="col" className='text-center'>Aadhar No.</th>
              <th scope="col" className='text-center' >Address</th>
              <th scope="col" className='text-center'>City</th>
              <th scope="col" className='text-center' >State</th>
              <th scope="col" className='text-center'>Zip</th>
              <th scope="col" className='text-center'>Status</th>
              <th scope="col" className='text-center'>Details</th>
            </tr>
          </thead>
          <tbody>
            {
              Details.map((element,index)=>{
            return <EList key = {index} No= {index + 1} Name = {element.username} ComplainType = {element.ComplainType}
            Email = {element.Email} PhoneNo = {element.PhoneNo} Address= {element.Address} Landmark= {element.Landmark}
             City = {element.City} Aadhar = {element.Aadhar} State = {element.State} Zip= {element.Zip}  Country = {element.Country} Status= {element.Status}
             id = {element._id} empAssign = {element.empAssign}  CAccount = {element.CAccount}  IFSC = {element.IFSC} Products = {element.Products} AccountName = {element.AccountName} />})
           }
          </tbody>
        </table>
   
    </>
  )
}

export default EmployeeList