import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


function EmpProfile() {
    const history = useNavigate();
    const [details, setDetails] = useState([])
    const [empProduct, setEmpProduct] = useState([])
    const hostname = process.env.REACT_APP_SERVER

    const empDetails = async () => {
        const url = `${hostname}/api/Employee/fetchDetails`
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "auth-token": localStorage.getItem('token')
            }
        })
        const data = await response.json()
        // console.log(data);
        setDetails(data);
        setEmpProduct(data.Products)
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {
            empDetails()
        }
        else {
            history('/')
        }
    }, [])

    return (

        <div className='container'>
            <h5 style={{ borderBottom: "solid" }} className="my-2"><i class="uil uil-bars">EMPLOYEE - My Profile</i></h5>


            <div><form className="row profile" >
                <div className="d-flex justify-content-center mb-3">
                    <button className="btn btn-dark btn-sm" id="DisBtn" style={{ borderRadius: "16px" }} disabled>Personal Details</button>
                </div>

                <div className="col-md-4">
                    <label className="form-label"><strong>Name : </strong> {details.username}</label>
                </div>
                <div className="col-md-4">
                    <label className="form-label"><strong>PhoneNo : </strong>{details.PhoneNo}</label>
                </div>
                <div className="col-md-4">
                    <label className="form-label"><strong>Email : </strong>{details.Email}</label>
                </div>

                <div className="col-md-4 ">
                    <label className="form-label"><strong>Address : </strong>{details.Address}</label>
                </div>

                <div className="col-md-4">
                    <label className="form-label"><strong>City : </strong>{details.City}</label>

                </div>
                <div className="col-md-4 ">
                    <label className="form-label"><strong>State: </strong>{details.State}</label>

                </div>

                <div className="col-md-4 ">
                    <label className="form-label"><strong>Zip : </strong>{details.Zip}</label>

                </div>

                <div className="d-flex justify-content-center">
                    <button className="btn btn-dark btn-sm my-3" id="DisBtn" style={{ borderRadius: "16px" }} disabled>Bank Details</button>
                </div>

                <div className="col-md-8 ">
                    <label className="form-label"><strong>Account Number : </strong>{details.CAccount}</label>
                </div>

                <div className="col-md-4">
                    <label className="form-label"><strong>IFSC Code : </strong>{details.IFSC}</label>
                </div>
                <div className="d-flex justify-content-center">
                    <button className="btn btn-dark btn-sm my-3" id="DisBtn" style={{ borderRadius: "16px" }} disabled>My Products</button>
                </div>

                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" className='text-center' >Product Name</th>
                            <th scope="col" className='text-center' >Quantity</th>
                        </tr>
                    </thead>
                    <tbody style={{ color: "var(--sidebar-text-clr)" }}>
                        {
                            empProduct.map((e, index) =>
                                <tr key={index} >

                                    <td className='text-center' >{e.Name}</td>
                                    <td className='text-center' >{e.Quantity}</td>
                                </tr>
                            )}
                    </tbody>
                </table>

            </form>
            </div>

        </div>
    )
}

export default EmpProfile