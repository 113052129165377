const initialState = []
const empPending = ( state =initialState , action)=>{
  
    if (action.type === "FETCH_EPT_DETAILS")
    {
        const EPending = action.payload 
        // console.log(EPending);
        return [...state , ...EPending ]
      
    }
    else
    {
    return state;
    }
}
export default empPending