import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import InContext from './InContext';

function Tckt(props) {
    const context = useContext(InContext);
    const {setResult } = context;
   
    const genInvoice =()=>{
      const details  = { index : props.index  , Name :props.Name  , ComplainType:props.ComplainType ,
    Email: props.Email ,PhoneNo :props.PhoneNo  ,  Address :props.Address , Landmark : props.Landmark ,
     City :props.City  ,  State : props.State ,  Zip : props.Zip  ,  Country :props.Country , Status :props.Status , id:props.id , empAssign : props.empAssign , TicketNo : props.TicketNo };
      setResult(details);
      // console.log(details);
    }


  return (
      <tr>
    <th scope="row" className='text-center sno'>{props.index }</th>
    <th scope="row" className='text-center tcktno'>{props.TicketNo }</th>
    <td className='text-center name'>{props.Name}</td>
    <td className='text-center complain'>{props.ComplainType}</td>
    <td className='text-center mail' >{props.Email}</td>
    <td className='text-center number' >{props.PhoneNo}</td>
    <td className='text-center address'>{props.Address}</td>
    <td className='text-center landmark'>{props.Landmark}</td>
    <td className='text-center city'>{props.City}</td>
    <td className='text-center state'>{props.State}</td>
    <td className='text-center zip'>{props.Zip}</td>
    {/* <td className='text-center country'>{props.Country}</td> */}
    <td className='text-center status'>
      <button className="btn  btn-sm" style={{ borderRadius: "16px" , background : "var(--cardPT-clr)",color :"white"}} type="button">{props.Status}</button>
    </td>
    <td className='text-center invoice'>
      <Link to="/Employee/GenInvoice"> <button className="btn btn-primary btn-sm" onClick={genInvoice} style={{ borderRadius: "16px" }} type="button">Generate</button></Link>
    </td>
  </tr>
  )
}

export default Tckt