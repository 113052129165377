import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

function EmpInventory() {
  let Edit = useSelector((state) => state.Edit)
  return (
    <div>
      <h5 style={{ borderBottom: "solid" }} className="my-2"><i className="uil uil-bars"> DASHBOARD - {Edit.Name}'s Inventory</i></h5>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th scope="col" className='text-center'>S.No</th>
            <th scope="col" className='text-center'>Product Name</th>
            <th scope="col" className='text-center'>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {
            Edit.Products.map((element, index) => {
              return <tr key={index}>
                <th scope="row" className='text-center'>{index + 1}</th>
                <td className='text-center'>{element.Name}</td>
                <td className='text-center'>{element.Quantity}</td>
              </tr>
            })}
        </tbody>
      </table>
    </div>
  )
}

export default EmpInventory