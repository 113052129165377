import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actionCreater } from '../../State';

function AssignItems() {
    const dispatch = useDispatch();
    var [itemCount, setItemCount] = useState(0);
    const [select1, setSelect1] = useState()
    const [select2, setSelect2] = useState()
    const hostname = process.env.REACT_APP_SERVER

    const [Details, setDetails] = useState([])
    const [Product, setProduct] = useState([])

    //                FETCHING EMPLOYEE
    const fetchEmp = async () => {
        const url = `${hostname}/api/Admin/Employee/fetchEmp`
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "auth-token": localStorage.getItem('token')
            }
        })
        const empDetails = await response.json()
        setDetails(empDetails)
    }

    //            FETCHING INVENTORY PRODUCTS
    const ProductDetails = async () => {
        const url = `${hostname}/api/Inventory/fetchProduct`
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "auth-token": localStorage.getItem('token')
            }
        })
        const prdctDtls = await response.json()
        setProduct(prdctDtls)
    }

    useEffect(() => { ProductDetails() })
    useEffect(() => { fetchEmp() })

    const add = () => {
        itemCount = itemCount + 1;
        setItemCount(itemCount)
    }
    const sub = () => {
        itemCount = itemCount - 1;
        setItemCount(itemCount)
    }


    const onchange1 = (event) => {
        setSelect1(event.target.value)
    }

    const onchange2 = (event) => {
        setSelect2(event.target.value)
    }

    const updateQuantity = async () => {
        const id = select1;
        const url = `${hostname}/api/Inventory/assignItems/${id}`
         await fetch(url,
            {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Name: select2, Quantity: itemCount })
            });
       
        alert("Added Successfully")
    }

    return (

        <div className="container py-5 h-100" style={{ width: "70vw", color: "white" }}>
            <form >
                <h5 className="fw-normal mb-3 pb-3 text-center" style={{ letterSpacing: "1px" }}>Assign Items to Employee </h5>

                <div className="form-outline mb-4">
                    <label className="form-label" >Select Employee</label>
                    <select className="form-select" onChange={onchange1} aria-label="Default select example">
                        <option selected>Select Employee</option>
                        {
                            Details.map((element, index) =>
                                <option key={index} value={element._id}>{element.username},{element.City}</option>
                            )}
                    </select>
                </div>

                <div className="form-outline mb-4">
                    <label className="form-label" >Select Items</label>
                    <select className="form-select" onChange={onchange2} aria-label="Default select example">
                        <option selected>Open this select menu</option>
                        {
                            Product.map((element, index) =>
                                <option key={index} value={element.ProductName}>{element.ProductName}</option>
                            )}
                    </select>
                    <label className="form-label" >Quantity of Item</label>
                    <button className="btn btn-dark mx-2 my-4" onClick={sub} type="button">-</button>
                    <label className="form-label" >{itemCount}</label>
                    <button className="btn btn-dark mx-2 my-2" onClick={add} type="button">+</button>
                </div>

                <div className="pt-1 mb-4">
                    <button className="btn btn-dark btn-sm btn-block" onClick={updateQuantity} type="button">Add Items</button>
                </div>
            </form>


        </div>

    )
}

export default AssignItems