import React,{useState,useEffect} from 'react'
import IList from './IList'

function Invoice() {
  const [INVOICE, setINVOICE] = useState([]);
  const hostname = process.env.REACT_APP_SERVER
   
  //               FETCHING INVOICE
   const InvoiceDetails = async () => {
    const url = `${hostname}/api/Admin/fetchInvoice`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const Invoice = await response.json()
    setINVOICE(Invoice)
  }
  useEffect(() => { InvoiceDetails() },[])


  return (
    <div>
      <h5 style={{ borderBottom: "solid" }} className="my-2"><i class="uil uil-bars">  INVOICE-List</i></h5>
      <div className='container-invoicelist my-3'>
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col" className='text-center'>S.No</th>
              <th scope="col" className='text-center' >Name</th>
              <th scope="col" className='text-center'>Mobile Number</th>
              <th scope="col" className='text-center' >Invoice Number</th>
              {/* <th scope="col" className='text-center'>Complaint Date</th> */}
              <th scope="col" className='text-center' >Invoice Date</th>
              <th scope="col" className='text-center'>Invoice</th>

            </tr>
          </thead>
          <tbody>
            {
              INVOICE.map((element, index) => {
                return <IList  key = {index} No= {index + 1} Name = {element.Name} ComplainType = {element.ComplainType}
                Email = {element.Email} PhoneNo = {element.PhoneNo} Address= {element.Address} Landmark= {element.Landmark}
                City = {element.City}  State = {element.State} Zip= {element.Zip}  Country = {element.Country} Products = {element.Products} 
                InDate = {element.InDate} DiscountedPrice = {element.DiscountedPrice} TotalPrice = {element.TotalPrice} Refer={element.Refer} InvoiceNo={element.InvoiceNo} Payments ={element.Payments}/> })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Invoice